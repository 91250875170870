import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import { setNavSearchCategory, setNavSearchOpen } from 'app/actions/navSearch';

import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import Icon from 'app/components/Svg';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';
import { isSearchFeatureEnabled } from 'app/utils/featureHelper';

import { getAuthorizeObject } from 'app/utils/entitiesHelper';

class ResourceUnavailableView extends Component {
  constructor(props) {
    super(props);
    let locationState = props.location.state;
    this.state = {
      resourceType: locationState?.resourceType,
      niceUrl: locationState?.niceUrl,
      url: locationState?.url,
    };
  }

  privateAccessDenied() {
    return (
      this.props.location.pathname ===
      withWorkspaceIdentifier('/private_access_denied')
    );
  }

  notFound() {
    return (
      this.props.location.pathname === withWorkspaceIdentifier('/not_found')
    );
  }

  renderIcon() {
    return (
      <div className='svg-icon-block'>
        {this.notFound() && <Icon name='svg404' />}
        {this.privateAccessDenied() && <Icon name='privacy' />}
      </div>
    );
  }

  renderHeading() {
    const searchText = isSearchFeatureEnabled()
      ? 'Would you like to search for something else'
      : '';
    if (this.notFound()) {
      return (
        <div className='heading-404'>
          <div className='big px-3 px-md-0'>
            The page you requested couldn’t be found
          </div>
          <div className='small-text px-3 px-md-0'>
            This content is no longer available.
            <br />
            {searchText}
          </div>
        </div>
      );
    } else if (this.privateAccessDenied()) {
      return (
        <div className='heading-404'>
          <div className='big px-3 px-md-0'>
            The privacy settings have been changed
          </div>
          <div className='small-text px-3 px-md-0'>
            This content is no longer available for public access.
            <br />
            Would you like to search for something else
          </div>
        </div>
      );
    }
  }

  shouldRedirectToRoot() {
    return (
      this.privateAccessDenied() &&
      !this.state.resourceType &&
      !this.state.niceUrl
    );
  }

  renderSearchContainer() {
    return (
      <label className='label-404-search'>
        <OrthoIcon name='search' dataHoverNotRequired={true} />
        <AuthorizeComponent
          onClick={this.props.setNavSearchOpen}
          abilityObject={getAuthorizeObject('search', 'all')}>
          <button className='form-control cursor-pointer'>
            Click here to search...
          </button>
        </AuthorizeComponent>
      </label>
    );
  }

  render() {
    if (this.shouldRedirectToRoot()) return <Navigate to='/' />;

    return (
      <div className='resource-unavailable'>
        <article className='text-center'>
          {this.renderIcon()}
          {this.renderHeading()}

          {isSearchFeatureEnabled() && this.renderSearchContainer()}
        </article>
      </div>
    );
  }
}
ResourceUnavailableView = withRouter(ResourceUnavailableView);

ResourceUnavailableView = connect(({ currentUser }) => ({ currentUser }), {
  setNavSearchCategory,
  setNavSearchOpen,
})(ResourceUnavailableView);
export default ResourceUnavailableView;
