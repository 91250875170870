import ConnectedActivityLogView from 'app/components/FeedView/ActivityLogView';
import TaskPreview from 'app/components/TaskPreviewModal/TaskPreview';
import { useSearchParams } from 'react-router-dom';
import { isOfPartnerTypeSpace, isOfTypeSpace } from 'app/utils/spaceHelper';

const TaskLogView = (props) => {
  const [searchParam, setSearchParam] = useSearchParams();
  const getSourceType = () => {
    return props.feed?.source_type;
  };

  const DisplayTaskWithCard = () => {
    let task = null;
    if (getSourceType() === 'GenericActivity') {
      switch (props.feed?.action) {
        case 'task_label_removed':
          task = props.feed.objects[0].entity;
          break;
        case 'task_assignee_updated':
        case 'task_description_updated':
        case 'task_due_date_updated':
          task = {
            ...props.feed.objects[0],
            parent_entity: props.feed.entities[0],
          };
          break;
        default:
          task = {
            ...props.feed.objects[0],
            parent_entity: props.feed.entities[0],
          };
      }
    } else if (getSourceType() === 'LabelAddedActivity') {
      task = props.feed.objects[0].entity;
    } else {
      task = {
        ...props.feed.objects[0],
        parent_entity: props.feed.entities[0],
      };
    }

    // insert the task id in the list of task id
    if (!props.feed?.showTaskActivityCard) {
      return <ConnectedActivityLogView {...props} />;
    }

    if (task.deleted_at) return null;

    return (
      <div class='activity-task-card task-wrapper'>
        <TaskPreview
          task={task}
          hideCloseBtn={true}
          showSeeMoreButton={true}
          loaderNotRequired={true}
          taskNotRequired={
            task.entity &&
            (isOfPartnerTypeSpace(task.entity) || isOfTypeSpace(task.entity))
          }
          nextPageLoadRequired={false}
        />
      </div>
    );
  };

  return <>{DisplayTaskWithCard()}</>;
};

export default TaskLogView;
