import React from 'react';
import { Outlet } from 'react-router-dom';
import { CustomNavigate as Navigate } from 'app/routes/Shared/CustomNavigate';
import { connect } from 'react-redux';
import { withApollo } from '@apollo/client/react/hoc';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import {
  logout,
  setCurrentUserGraph,
  updateCurrentUserGraph,
  verifyUserEmail,
} from 'app/actions/authentication';
import { setWorkspaceData } from 'app/actions/workspace';
import { updateBannerValues } from 'app/actions/universalBanner';
import LogRocket from 'logrocket';

import AbstractLayout from './AbstractLayout';
import AllModalsContainer from 'app/components/AllModalsContainer';
import Footer from 'app/components/Shared/Footer';
import LeftNavLinks from 'app/components/LeftNavLinks';
import RightNavBar from 'app/components/NavLinks/RightNavBar';
import SystemNotifications from 'app/components/SystemNotifications';
import WebPushServiceWorker from 'app/components/WebPushServiceWorker';
import withAuthorize from 'app/components/HOC/withAuthorize';
import UniversalBanner from 'app/components/HOC/UniversalBanner';
import EventTracker from 'app/services/EventTracker';
import { bindMobileFilter } from 'app/actions/device';
import { resetNewPost } from 'app/actions/postForm';
import { isEqual } from 'app/utils/osLodash';
import { isCurrentUser } from 'app/utils/userHelper';
import { openOnboardingVerificationModals } from 'app/actions/onboardingTips';
import { openInfoModal } from 'app/actions/infoModal';
import { isWorkspaceView } from 'app/utils/Workspace/generalHelper';
import { actionByGuestUser } from 'app/actions/guestUser';

import LocalStorageManager from 'app/services/LocalStorageManager';
import GlobalSearchInputField from 'app/components/GlobalSearchView/GlobalSearchInputField';

class AuthenticatedLayout extends AbstractLayout {
  customComponentDidMount() {
    this.bindTabCloseEvent();
    this.props.actionByGuestUser('', {});
    if (this.props.currentUser.graph) {
      const user = this.props.currentUser.graph;
      this.props.setCurrentUserGraph(user);
      this.openVerificationModals(user);
      EventTracker.identify(user.id);
      EventTracker.register(user);
      this.sendEmailVerificationRequestIfRequired();
    }
    if (process.env.REACT_APP_LOGROCKET_CODE)
      LogRocket.init(process.env.REACT_APP_LOGROCKET_CODE);

    const user = this.props.currentUser.graph;
    if (user) EventTracker.register(user);
  }

  componentDidUpdate(prevProps) {
    this.hideFooter();
    if (!isEqual(this.props.location.pathname, prevProps.location.pathname))
      this.props.resetNewPost();
  }

  componentWillUnmount() {
    this.unbindTabCloseEvent();
  }

  openVerificationModals(user) {
    if (isWorkspaceView() && user.next_verification_step) {
      this.props.openOnboardingVerificationModals(
        user.profile_incomplete_threshold_reached,
      );
    }
  }

  sendEmailVerificationRequestIfRequired() {
    let verifyLoginId = LocalStorageManager.get('verifyEmailOnLoginId');
    if (verifyLoginId && isCurrentUser({ id: verifyLoginId }))
      this.props.verifyUserEmail();
  }

  onSuccessfulVerification = (data) => {
    return new Promise((resolve) => {
      this.props.updateCurrentUserGraph({
        ...this.props.currentUser.graph.user,
        number_verified: true,
      });
      resolve();
    });
  };

  logout = () => {
    this.props.logout();
  };

  sendActiveSessionEvent = () => {
    if (this.props.currentUser.graph)
      EventTracker.activeSession(this.props.currentUser.graph.email);
  };

  browserTabCloseEvent() {
    return this.props.device.ipad || this.props.device.mobile
      ? 'pagehide'
      : 'beforeunload';
  }

  bindTabCloseEvent() {
    window.addEventListener(
      this.browserTabCloseEvent(),
      this.sendActiveSessionEvent,
    );
  }

  unbindTabCloseEvent() {
    window.removeEventListener(
      this.browserTabCloseEvent(),
      this.sendActiveSessionEvent,
    );
  }

  isEditPageOrClvScreen() {
    const { pathname } = this.props.location;
    return (
      pathname.match(/^\/spaces\/(.+\/edit|new)\/?$/) ||
      pathname.match(/^\/spaces\/(.+\/subspaces\/new)\/?$/) ||
      pathname.match(/^\/cases\/(.+\/edit|new)\/?$/) ||
      pathname.match(/^\/spaces\/(.+\/preview_contents)\/?$/) ||
      pathname.match(/^\/team\/(.+\/dm|group)\/?$/) ||
      pathname.includes('/team')
    );
  }

  hideFooter = () => {
    setTimeout(() => {
      if (this.isEditPageOrClvScreen()) {
        document.getElementById('footer').classList.add('hidden');
      } else {
        document.getElementById('footer').classList.remove('hidden');
      }
    }, 500);
  };

  renderContent() {
    if (this.props.currentUser.graph) {
      return <>{this.props.skipOutlet ? this.props.children : <Outlet />}</>;
    } else {
      return null;
    }
  }

  renderNavLinks() {
    const user = this.props.currentUser.graph,
      workspace = this.props.workspace.data;

    return <LeftNavLinks user={user} workspace={workspace} />;
  }

  renderSearchField() {
    return <GlobalSearchInputField />;
  }

  renderWebNav() {
    return (
      <nav className='navbar navbar-expand-lg navbar-expand-xl os-main-nav'>
        <div className='os-main-nav-inner'>
          {this.renderNavLinks()}
          {this.renderSearchField()}
          <RightNavBar />
        </div>
      </nav>
    );
  }

  renderNavbar() {
    return this.props.device.mobileDevice
      ? this.renderNavLinks()
      : this.renderWebNav();
  }

  // If a user has an invalid password or hasn't changed the password in 30 days.
  updatePasswordMandatory() {
    return (
      !this.props.currentUserSessionValid.valid ||
      this.props.currentUser.graph.password_update_required
    );
  }

  isNumberUpdateRequired() {
    return this.props.currentUser.graph.number_update_required;
  }

  render() {
    if (this.updatePasswordMandatory())
      return <Navigate to='/update_password' skip={true} />;
    if (this.isNumberUpdateRequired())
      return <Navigate to='/update_number' skip={true} />;

    // TODO: CloudberryRevisit
    // if (graph && graph.policies_acceptance_required)
    //   return <PolicyNotAcceptedView />;

    // if (
    //   graph &&
    //   graph.care_policy_acceptance_required &&
    //   isCareWithRecords(graph)
    // )
    //   return <PolicyNotAcceptedView kind="care_policy" />;

    // if (
    //   (isSourceWorkspace()) &&
    //   graph &&
    //   graph.profile_incomplete_threshold_reached
    // )
    //   return <Navigate to="/profile_incomplete" />;

    let { guestUser, location } = this.props;
    if (
      guestUser &&
      guestUser.metaInfo &&
      guestUser.metaInfo.url &&
      guestUser.metaInfo.url !== location.pathname
    ) {
      return (
        <Navigate
          to={guestUser.metaInfo.url}
          skip={guestUser.action === 'magic_link_redirect'}
        />
      );
    }

    return (
      <div className='layout'>
        <WebPushServiceWorker />
        {this.renderNavbar()}
        {<UniversalBanner />}
        {this.renderContent()}
        <AllModalsContainer />
        <SystemNotifications />
        <Footer usePortal={true} />
      </div>
    );
  }
}

AuthenticatedLayout = withApollo(AuthenticatedLayout);
AuthenticatedLayout = withAuthorize(AuthenticatedLayout);
AuthenticatedLayout = connect(
  ({
    currentUser,
    device,
    currentRelease,
    currentUserSessionValid,
    guestUser,
    presentationMode,
    systemConfig,
    workspace,
  }) => ({
    currentUser,
    device,
    currentRelease,
    currentUserSessionValid,
    guestUser,
    presentationMode,
    systemConfig,
    workspace,
  }),
  {
    actionByGuestUser,
    setCurrentUserGraph,
    setWorkspaceData,
    logout,
    bindMobileFilter,
    openInfoModal,
    openOnboardingVerificationModals,
    resetNewPost,
    updateBannerValues,
    updateCurrentUserGraph,
    verifyUserEmail,
  },
)(AuthenticatedLayout);
AuthenticatedLayout = withRouter(AuthenticatedLayout);
export default AuthenticatedLayout;
