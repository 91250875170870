import useCursorCollaboration from 'app/hooks/useCursorCollaboration';
import Cursor from 'app/components/Collaboration/Cursor';

const CursorCollaboration = () => {
  const { coordinates } = { coordinates: [] };

  return (
    <>
      {coordinates.length > 0 &&
        coordinates.map((cursor) => {
          return (
            <Cursor
              cursor={cursor?.cursor}
              color='#5d7893'
              name={cursor?.userName}
            />
          );
        })}
    </>
  );
};

export default CursorCollaboration;
