import store from 'app/store';

import { isEmpty } from 'app/utils/osLodash';

export function getvalidityDaysSetting(values) {
  let validityDaysSetting =
    store.getState().systemConfig.configs['user_session_validity_in_days'];
  if (!isEmpty(validityDaysSetting)) {
    return parseFloat(validityDaysSetting.value);
  }
}
