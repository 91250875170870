import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import AuthorizeComponent from 'app/components/Shared/AuthorizeComponent';
import FoldersListing from 'app/components/FoldersView/Listing';
import NewFolderButton from 'app/components/FoldersView/NewFolderButton';
import NonSubscribedView from 'app/components/NonSubscribedView';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import OsBtn from 'app/components/OsBtn/';
import OsField from 'app/components/OsField';
import RecordsView from 'app/components/RecordsView';
import SubSpacesListing from 'app/components/BoardView/SubSpacesListing';
import VisitorsListing from 'app/components/VisitorsListing';

import { SPACE_DETAIL_RECORDS_PER_PAGE } from 'app/components/BoardView/constants';
import { debounce } from 'app/utils/osLodash';
import {
  isNewSubSpaceAllowedInWorkspace,
  isWorkspaceAuthorOrEditor,
  isWorkspaceView,
} from 'app/utils/Workspace/generalHelper';
import { entityUrl, getAuthorizeObject } from 'app/utils/entitiesHelper';
import { addClassToHTML, removeClassFromHTML } from 'app/utils/domHelper';
import { isWorkspaceType } from 'app/utils/spaceHelper';

import { openPatientModal } from 'app/actions/patientModal';

class ContentListing extends Component {
  state = {
    changeKey: false,
    searchText: '',
    inputActive: false,
    folderCreationEnabled: false,
  };

  componentDidMount() {
    isWorkspaceView() && addClassToHTML('detail-page');
  }

  componentWillUnmount() {
    isWorkspaceView() && removeClassFromHTML('detail-page');
  }

  getSpace() {
    return this.props.data.space;
  }

  isTeamSpacesListing() {
    return this.props.tabDetails.kind === 'team_spaces';
  }

  isFoldersListing() {
    return this.props.tabDetails.kind === 'folders';
  }

  isSubspaceListing() {
    return this.props.tabDetails.kind === 'subspaces';
  }

  isCareSpacesTab() {
    return this.props.tabDetails.kind === 'carespaces';
  }

  onNewSubspaceClick = () => {
    this.props.navigate(
      `${entityUrl(this.getSpace(), false, {
        skipWorkspace: true,
      })}/subspaces/new`,
    );
  };

  renderNewSubSpaceButton() {
    if (isWorkspaceAuthorOrEditor()) {
      return (
        <AuthorizeComponent
          abilityObject={getAuthorizeObject('create', 'board')}
          onClick={this.onNewSubspaceClick}>
          <div className='d-flex justify-content-end'>
            <OsBtn
              text='Create Space'
              extraClass='mb-3'
              name='BtnPrimary'
              associatedEntity={this.getSpace()}
            />
          </div>
        </AuthorizeComponent>
      );
    }
  }

  openPatientModal = () => {
    this.props.openPatientModal(null, { source: 'care-space-tab' });
  };

  renderAddNewPatientButton() {
    if (isWorkspaceAuthorOrEditor()) {
      return (
        <div className='d-flex justify-content-end '>
          <OsBtn
            text='Add New Patient'
            extraClass=''
            name='BtnPrimary'
            associatedEntity={this.getSpace()}
            onClick={this.openPatientModal}
          />
        </div>
      );
    }
  }

  toggleCreateFolderButton = () => {
    this.setState({ folderCreationEnabled: !this.state.folderCreationEnabled });
  };

  renderPrimaryCTA() {
    if (!this.props.device.mobileDevice)
      if (
        (this.isSubspaceListing() || this.isTeamSpacesListing()) &&
        isNewSubSpaceAllowedInWorkspace()
      ) {
        return this.renderNewSubSpaceButton();
      } else if (this.isCareSpacesTab()) {
        return this.renderAddNewPatientButton();
      } else if (this.isFoldersListing() && isWorkspaceAuthorOrEditor()) {
        return <NewFolderButton />;
      }
  }

  isSearchRequired() {
    return this.props.tabDetails.search_required && this.props.searchRequired;
  }

  setSearchText = debounce((searchText) => {
    this.setState({ searchText });
  }, 500);

  onChange = ({ target }) => {
    let searchText = target.value;
    searchText = (searchText && searchText.trim()) || '';
    this.setSearchText(searchText);
  };

  setInputActive = () => {
    this.setState({ inputActive: true });
  };

  unsetInputActive = () => {
    this.setState({ inputActive: false });
  };

  renderSearch() {
    return (
      <div className='input-wrapper nav-search-holder input-focus space-search-container'>
        <div
          className={`nav-search-container ${
            this.state.inputActive ? 'input-focus-active' : ''
          }`}
          onFocus={this.setInputActive}
          onBlur={this.unsetInputActive}>
          <label className='position-relative m-0 w-100'>
            <OrthoIcon name='search' dataHoverNotRequired={true} />
            <OsField
              osType='input'
              type='text'
              placeholder='Search here'
              className='nav-search-input'
              name='search'
              onChange={this.onChange}
            />
          </label>
        </div>
      </div>
    );
  }

  getCardViewType() {
    return this.props.searchRequired ? 'extra-small' : this.props.cardViewType;
  }

  getSearchQuery() {
    return this.props.searchRequired
      ? this.state.searchText
      : this.props.searchText;
  }

  renderContent() {
    let searchText = this.getSearchQuery(),
      cardViewType = this.getCardViewType();
    if (this.isSubspaceListing() || this.isTeamSpacesListing()) {
      return (
        <SubSpacesListing
          space={this.getSpace()}
          scrollIfNeeded={this.props.scrollIfNeeded}
          searchQuery={searchText}
          cardViewType={cardViewType}
        />
      );
    } else if (this.isFoldersListing()) {
      return (
        <NonSubscribedView
          title='My Folders'
          primaryCTAText='Add New Folder'
          headerNotRequired={true}>
          <FoldersListing
            space={this.getSpace()}
            searchQuery={searchText}
            folderCreationEnabled={this.state.folderCreationEnabled}
            toggleCreateFolderButton={this.toggleCreateFolderButton}
            cardViewType={cardViewType}
          />
        </NonSubscribedView>
      );
    } else {
      return (
        <>
          <RecordsView
            space={this.getSpace()}
            type='TabSections'
            navTab={this.props.tabDetails}
            idQuery={this.props.tabDetails.id}
            perPage={SPACE_DETAIL_RECORDS_PER_PAGE}
            trackScrolling={true}
            size={cardViewType}
            queryType={'TAB_DETAILS'}
            searchQuery={searchText.trim()}
            scrollIfNeeded={this.props.scrollIfNeeded}
            contentNotAccessible={this.props.contentNotAccessible}
            additional_filters={{ filter_values: this.props.filterValues }}
            sortQuery={this.props.sortQuery}
          />
        </>
      );
    }
  }

  getTabHeader(tabDetails) {
    if (tabDetails.kind === 'folders') {
      return 'System Folders';
    } else {
      return tabDetails.name;
    }
  }

  renderTabName() {
    if (isWorkspaceType(this.getSpace()))
      return (
        <div className='workspace-space-page-heading col'>
          {this.getTabHeader(this.props.tabDetails)}
        </div>
      );
  }

  renderSearchContainer() {
    if (isWorkspaceType(this.getSpace())) {
      return <div className='container-fluid px-0'>{this.renderSearch()}</div>;
    } else {
      return this.renderSearch();
    }
  }

  renderWorkspacePageHead() {
    return (
      <div className='container-fluid px-0 workspace-page-head'>
        <div className='row d-flex justify-content-between'>
          {this.renderTabName()}
          <div className='page-heading-right col'>
            {this.isSearchRequired() && this.renderSearchContainer()}
            {this.renderPrimaryCTA()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.isCareSpacesTab())
      return <VisitorsListing filter={this.props.filter} />;

    return (
      <>
        {this.renderWorkspacePageHead()}
        {this.renderContent()}
      </>
    );
  }
}

ContentListing = connect(({ device }) => ({ device }), { openPatientModal })(
  ContentListing,
);
ContentListing.defaultProps = {
  scrollIfNeeded: () => {},
  tabDetails: {},
  searchRequired: true,
};
ContentListing = withRouter(ContentListing);
export default ContentListing;
