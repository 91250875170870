import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { graphql } from '@apollo/client/react/hoc';

import RecordsView from 'app/components/RecordsView';

import { SPACE_DETAIL_RECORDS_PER_PAGE } from './constants';
import {
  resetActiveDragDetails,
  setActiveDragDetails,
} from 'app/actions/spaceLinks';

import { DragAndDropContext } from 'app/components/DnD/DragAndDropContext';
import StoreUpdater from 'app/services/StoreUpdater';
import { isGuestUser } from 'app/utils/userHelper';

const UPDATE_SUBSPACE_POSITION_MUTATION = queryLoader(
  'app/graphql/mutations/UpdateSubspacePosition.gql',
);

class SubSpacesListing extends Component {
  getSpace() {
    return this.props.space;
  }

  handleDragEnd = (e) => {
    this.props.resetActiveDragDetails();
    if (e.active && e.over) {
      let spaceId = e.active.id.split(':')[1],
        sanitizedOverId = e.over.id.replace('temp', ''),
        moveBeforeSpaceId = sanitizedOverId.split(':')[1] || e.over.id;

      this.props
        .updateSubspacePosition({
          variables: { spaceId, moveBeforeSpaceId },
        })
        .then(({ data }) => {
          data.updateSubspacePosition.success &&
            StoreUpdater.updateSubspaceListing(
              spaceId,
              moveBeforeSpaceId,
              this.getQueryProps(),
            );
        });
    }
  };

  getQueryProps() {
    return {
      addNewLinkNotRequired: true,
      type: 'SubSpaces',
      idQuery: this.getSpace().nice_id,
      perPage: SPACE_DETAIL_RECORDS_PER_PAGE,
      searchQuery: this.props.searchQuery,
    };
  }

  handleDragStart = (e) => {
    let dragId = e.active.id.split(':')[1];
    this.props.setActiveDragDetails({ activeDragType: 'link', dragId });
  };

  canArrangeContent() {
    return this.props.device.desktop && this.getSpace().is_author_or_editor;
  }

  renderSubSpaces() {
    let canArrangeContent = this.canArrangeContent(),
      className = canArrangeContent ? ' manageable-sections ' : '';

    return (
      <div className={className}>
        <RecordsView
          queryType={'SPACE_DETAILS'}
          board={this.getSpace()}
          scrollIfNeeded={this.props.scrollIfNeeded}
          cardGridIdentifier={'SpaceDetail:Card'}
          cardProps={{
            canArrangeContent: canArrangeContent,
            cardsCustomMarginRequired: canArrangeContent,
            size: 'extra-small',
          }}
          {...this.getQueryProps()}
        />
      </div>
    );
  }

  render() {
    return (
      <DragAndDropContext
        onDragStart={this.handleDragStart}
        onDragMove={this.handleDragMove}
        onDragEnd={this.handleDragEnd}>
        {this.renderSubSpaces()}
      </DragAndDropContext>
    );
  }
}

SubSpacesListing.defaultProps = {
  scrollIfNeeded: () => {},
};
SubSpacesListing = graphql(UPDATE_SUBSPACE_POSITION_MUTATION, {
  name: 'updateSubspacePosition',
})(SubSpacesListing);
SubSpacesListing = connect(({ device }) => ({ device }), {
  resetActiveDragDetails,
  setActiveDragDetails,
})(SubSpacesListing);
export default SubSpacesListing;
