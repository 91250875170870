import React from 'react';

import Avatar from 'app/components/Shared/Avatar';

import { orderedDegrees } from 'app/utils/generalHelper';
import logoPrimary from 'app/images/qlark_icon.png';
import { avatarAttributes } from 'app/utils/entitiesHelper';
import { slice, truncate } from 'app/utils/osLodash';

// Small reusable component that shows the user for card views
export default ({
  name,
  degree_list,
  avatar,
  initials,
  featured,
  avoidTruncate,
  children,
  sliceDegreeUpto = 4,
  __typename,
}) => {
  let userAttributes = {
    avatar: featured ? logoPrimary : avatar,
    name,
    initials,
    __typename,
  };
  return (
    <div className='user'>
      {
        <Avatar
          className={'avatar avatar-32 me-2'}
          {...avatarAttributes(userAttributes)}
        />
      }

      <div className='user__info text-truncate'>
        {
          <div>
            <p className='user-name'>
              {avoidTruncate ? name : truncate(name, { length: 15 })}
              {degree_list.length
                ? `, ${
                    degree_list &&
                    slice(orderedDegrees(degree_list), 0, sliceDegreeUpto).join(
                      ' ',
                    )
                  }`
                : ''}
            </p>
          </div>
        }
        <p className='user-title'>{children}</p>
      </div>
    </div>
  );
};
