import gql from 'graphql-tag';

const FRAGMENT_HASH = {
  viewed_space_content: `viewed_space_content {
    id
    meta_info
  }`,
  emoji_reaction_detail: `emoji_reaction_detail {
    id
    emoji_reaction_users {
      id
      identifier
      reacted_by_current_user
      user {
        avatar
        degree_list
        first_name
        full_name
        id
        initials
        large_avatar
        last_name
        name
        nice_id
        nice_url
        role_type
      }
    }
  }`,
  space_share_links: `share_links {
    active
    created_at
    expired_at
    id
    name
    open_link
    url
  }`,
};

export function getEntityUpdateFragment(meta) {
  let fragmentValue =
    FRAGMENT_HASH[meta.attributeName] ||
    meta.attributeName ||
    (meta.attributeNames || []).join('\n');
  return gql`
      fragment ${meta.fragmentName} on ${meta.entity.__typename} {
        id
        ${fragmentValue}
      }
    `;
}
