import store from 'app/store';
import { isGuestUser } from 'app/utils/userHelper';
import {
  isTouNotAccepted,
  isSpaceOwner,
  tabNotAvailableAsUserNotVerified,
  isCareSpace,
} from 'app/utils/spaceHelper';
import { isCareWorkspaceView } from 'app/utils/Workspace/generalHelper';

import { CONTENT_ACCESSIBLE_PERMISSION_TYPES } from 'app/components/BoardView/constants';

export function getPermissionAttributeBasedOnUser(entity) {
  let attribute = '';
  if (isGuestUser()) {
    attribute = 'guest_permission';
  } else if (isCareWorkspaceView()) {
    attribute = 'patient_permission';
  } else if (!entity.is_author_or_collaborator) {
    attribute = 'logged_in_permission';
  } else if (entity.is_author_or_editor) {
    attribute = 'editor_permission';
  } else if (entity.is_author_or_member) {
    attribute = 'member_permission';
  }

  return attribute;
}

export function isContentListingTab(tab) {
  return tab.content_type === 'listing';
}

export function isTabVisibleToNonSpaceOwners(tab, entity) {
  return (
    entity.is_author_or_editor ||
    !isContentListingTab(tab) ||
    tab.content_count > 0 ||
    isCareSpace(entity)
  );
}

export function contentAccessibleTabs(entity, propertyName) {
  let tabs =
    propertyName === 'nav_tabs'
      ? getVisibleTabs(entity)
      : entityLecturePreviewTabs(entity);
  if (isSpaceOwner(entity)) {
    return tabs;
  } else {
    let attribute = getPermissionAttributeBasedOnUser(entity);
    if (attribute) {
      return tabs.filter(
        (tab) =>
          (!isTouNotAccepted(entity) || tab.kind === 'information') &&
          CONTENT_ACCESSIBLE_PERMISSION_TYPES.includes(tab[attribute]) &&
          !tabNotAvailableAsUserNotVerified(entity, tab.kind),
      );
    } else {
      return [];
    }
  }
}

function entityLecturePreviewTabs(entity) {
  return entity.lecture_preview_tabs || [];
}

export function getLecturePreviewTabs(entity) {
  if (isSpaceOwner(entity)) {
    return entityLecturePreviewTabs(entity);
  } else {
    let attribute = getPermissionAttributeBasedOnUser(entity);
    return attribute
      ? entityLecturePreviewTabs(entity).filter(
          (tab) => tab[attribute] !== 'none',
        )
      : [];
  }
}

export function getNonLecturePreviewTabs(entity) {
  let lecturePreviewTabIds = entityLecturePreviewTabs(entity).map(
    (tab) => tab.id,
  );
  return (
    entity.nav_tabs?.filter((tab) => !lecturePreviewTabIds.includes(tab.id)) ||
    []
  );
}

export function isLecturePreviewTab(selectedFilter, entity) {
  let paths = entityLecturePreviewTabs(entity).map((tab) => tab.path);
  return paths.includes(selectedFilter);
}

export function getVisibleTabs(entity) {
  let { nav_tabs = [] } = entity;
  if (isSpaceOwner(entity)) {
    return nav_tabs;
  } else {
    let attribute = getPermissionAttributeBasedOnUser(entity),
      tabs = entity.is_author_or_editor
        ? nav_tabs
        : getNonLecturePreviewTabs(entity);
    return attribute
      ? tabs.filter(
          (tab) =>
            tab[attribute] !== 'none' &&
            isTabVisibleToNonSpaceOwners(tab, entity),
        )
      : [];
  }
}

export function isNavNotListRequired(entity) {
  let visibleTabs = getVisibleTabs(entity);
  return (
    visibleTabs.length === 1 &&
    CONTENT_ACCESSIBLE_PERMISSION_TYPES.includes(
      visibleTabs[0][getPermissionAttributeBasedOnUser(entity)],
    )
  );
}

export function isOnDiscussionsTab(path, space) {
  return space.nav_tabs
    .filter((tab) => tab.content_type === 'discussion')
    .some((tab) => tab.path === path);
}

export function clvViewRequired(space) {
  return getLecturePreviewTabs(space).length > 0;
}

export function getNavTab(space, path) {
  return space.nav_tabs.find((tab) => tab.path === path);
}
