import React, { Component } from 'react';
import { connect } from 'react-redux';

import client from 'app/graphql/client';

const ADDITIONAL_FILTERS_FOR_QUERY_TYPE = [
  'TAB_DETAILS',
  'USER_DETAIL',
  'TASK_FEED_LISTING',
  'VISTIOR_DIRECTORY',
  'TaskDirectory',
];
const SEARCH_QUERY_FOR_QUERY_TYPE = ['SPACE_DETAILS', 'TAB_DETAILS'];
const SORT_QUERY_FOR_QUERY_TYPE = [
  'TAB_DETAILS',
  'USER_DETAIL',
  'VISTIOR_DIRECTORY',
];

export const ADDITIONAL_QUERIES_PARAMETERS_AND_PROPS = {};

const withCachedData = (WrappedComponent) => {
  class HOC extends Component {
    cachedRecords() {
      if (this.props.mergeCachedData) {
        // Note: Currently not being used anywhere
        try {
          let data = {
            ...client.readQuery({
              query: this.getQuery(),
              variables: this.getInitialRequestVariables(),
            }),
          };
          if (data && data.records.results.length > data.records.total) {
            data.records.results = data.records.results.slice(
              0,
              data.records.total,
            );
          }
          return data;
        } catch (e) {}
      }
    }

    getQuery() {
      return WrappedComponent.defaultProps.queryMapper[this.props.queryType];
    }

    getInitialRequestVariables() {
      const props = this.props,
        additionalDetails = ADDITIONAL_QUERIES_PARAMETERS_AND_PROPS[props.type];

      return {
        page: 0,
        type: props.type,
        perPage: props.perPage,
        text_query: props.textQuery,
        id_query: props.idQuery && props.idQuery.toString(),
        ...(SEARCH_QUERY_FOR_QUERY_TYPE.includes(props.queryType) && {
          searchQuery: props.searchQuery,
        }),
        ...(additionalDetails &&
          additionalDetails['additionalArguments'] &&
          additionalDetails['additionalArguments'](props)),
        ...(ADDITIONAL_FILTERS_FOR_QUERY_TYPE.includes(props.queryType) && {
          additional_filters: JSON.stringify(
            !!props.additional_filters ? props.additional_filters : {},
          ),
        }),
        ...(SORT_QUERY_FOR_QUERY_TYPE.includes(props.queryType) && {
          sortQuery: props.sortQuery,
        }),
      };
    }

    render() {
      return (
        <WrappedComponent
          cachedRecords={this.cachedRecords()}
          variables={this.getInitialRequestVariables()}
          {...this.props}
        />
      );
    }
  }

  HOC = connect(({ spaceLinks }) => ({ spaceLinks }), null)(HOC);
  return HOC;
};

export default withCachedData;
