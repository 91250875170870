import React, { Component } from 'react';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { connect } from 'react-redux';

import ConnectToSupport from 'app/components/ConnectToSupport';
import CustomDropdown from 'app/components/Shared/CustomDropdown';
import Logout from 'app/components/Shared/Logout';
import OsBtn from 'app/components/OsBtn';
import OsLink from 'app/components/OsLink';

import { orderedDegrees } from 'app/utils/generalHelper';
import { openPasswordUpdateModal } from 'app/actions/currentUserSessionValid';
import { openOnboardingModalWithScreen } from 'app/actions/onboardingTips';
import {
  isCareWorkspaceView,
  withWorkspaceIdentifier,
} from 'app/utils/Workspace/generalHelper';

import { entityUrl } from 'app/utils/entitiesHelper';
import { setKeyValueInLocalStorage } from 'app/components/SwitchProfile/quickSwitcherHelper';
import { isMobileDevice } from 'app/utils/deviceHelper';

class NavProfile extends Component {
  state = {
    dropdownOpen: false,
  };

  onBackgroundClick = (e) => {
    e.preventDefault();
    this.setState({ dropdownOpen: false });
  };

  renderBackground() {
    if (this.state.dropdownOpen) {
      return (
        <div
          className='nav-profile__dropdown-background active'
          onClick={this.onBackgroundClick}
          onTouchEnd={this.onBackgroundClick}
        />
      );
    } else {
      return <div />;
    }
  }

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  getClassName(link) {
    let className = 'nav-profile__link ',
      { pathname } = this.props.location;
    if (
      pathname.endsWith(link) ||
      (link === 'userProfile' &&
        pathname === '/users/' + this.props.currentUser.graph.nice_url)
    )
      return className + 'nav-link-selected';
    return className;
  }

  renderSupportTab() {
    return (
      <div key='support'>
        <ConnectToSupport
          className='nav-profile__link'
          viewType='link'
          iconName='support'
          text='Support'
          onRedirectToSupport={this.toggleDropdown}
        />
      </div>
    );
  }

  renderDropdownHeader(user) {
    if (user)
      return (
        <OsLink
          to={entityUrl(user)}
          className='nav-profile__user'
          onClick={() => this.setState({ dropdownOpen: false })}>
          <div className='nav-profile__user-info' key='user_name'>
            <div className='user-name'>{user.name}</div>
            <span className='user-title normal'>
              {orderedDegrees(user.degree_list).join(' ')}
            </span>
          </div>
        </OsLink>
      );
  }

  renderPublicProfileLink(user) {
    if (user)
      return (
        <div className='nav-profile__links' key='my_profile'>
          <OsLink
            to={entityUrl(user)}
            className={this.getClassName('userProfile')}
            iconName='account'
            text='My Profile'
          />
        </div>
      );
  }

  renderAccountSettings() {
    return (
      <div key='account_settings'>
        <OsLink
          to={withWorkspaceIdentifier('/account_settings')}
          className={this.getClassName('account_settings')}
          iconName='setting'
          text='Account Settings'
        />
      </div>
    );
  }

  renderUpdatePasswordCTAIfRequired(user) {
    return (
      <OsBtn
        name='BtnIcon'
        icon='locked'
        extraClass='w-100 onboarding-tip-btn'
        text='Update Password'
        onClick={this.props.openPasswordUpdateModal}
        associatedEntity={user}
      />
    );
  }

  renderTwoFactorAuthentication(user) {
    return (
      <OsBtn
        name='BtnIcon'
        icon='phone'
        extraClass='w-100 onboarding-tip-btn'
        onClick={this.props.openOnboardingModalWithScreen.bind(
          this,
          'mobile_verification',
        )}
        text='Update Phone Number'
        associatedEntity={user}
      />
    );
  }

  renderLogoutButton() {
    return <Logout key='logout' className='os-btn-link nav-profile__link' />;
  }

  redirectTo() {
    const isPasswordPresent =
      this.props.workspace.data.user_request.password_present;
    if (isPasswordPresent) {
      setKeyValueInLocalStorage('storage', true);
      setKeyValueInLocalStorage('lockNav', true);
      setKeyValueInLocalStorage('identifier', this.props.workspace.identifier);
    }
  }

  renderQuickSwitcherButton() {
    const isPasswordPresent =
      this.props.workspace.data.user_request?.password_present;
    return (
      <div key='quick_switcher'>
        <OsLink
          to={withWorkspaceIdentifier(
            isPasswordPresent ? '/profile/switcher' : '/profile/set_password',
          )}
          className={this.getClassName('account_settings')}
          iconName='locked'
          text='Lock Screen/Switch User'
          onClick={() => this.redirectTo()}
        />
      </div>
    );
  }

  renderDropdownOptions() {
    const user = this.props.currentUser.graph;
    const isProfileSwitcherEnabled =
      !isCareWorkspaceView() &&
      this.props.workspace?.data?.profile_switcher_enabled;
    return (
      <CustomDropdown
        name='user_profile_navigation'
        className='navbar-nav nav-profile '
        dropdownClass='os-dropdown user-profile__dropdown '
        viewType='navProfile'
        user={this.props.currentUser.graph}>
        {this.renderDropdownHeader(user)}
        {this.renderPublicProfileLink(user)}
        {this.renderAccountSettings()}
        {this.renderSupportTab()}
        {!isMobileDevice() &&
          isProfileSwitcherEnabled &&
          this.renderQuickSwitcherButton()}
        {this.renderLogoutButton()}
      </CustomDropdown>
    );
  }

  renderCareProductDropdwonOptions() {
    const user = this.props.currentUser.graph;

    return (
      <CustomDropdown
        name='user_profile_navigation'
        className='navbar-nav nav-profile '
        dropdownClass='os-dropdown user-profile__dropdown '
        viewType='navProfile'
        user={this.props.currentUser.graph}>
        {this.renderDropdownHeader(user)}
        {this.renderUpdatePasswordCTAIfRequired(user)}
        {this.renderTwoFactorAuthentication(user)}
        {this.renderSupportTab()}
        {this.renderLogoutButton()}
      </CustomDropdown>
    );
  }

  renderOptions() {
    if (isCareWorkspaceView()) {
      return this.renderCareProductDropdwonOptions();
    } else {
      return this.renderDropdownOptions();
    }
  }

  // Todo: handle tooltips for general user
  render() {
    return this.renderOptions();
  }
}

NavProfile = connect(
  ({ currentUser, onboardingTips, workspace, lockNavbar }) => ({
    currentUser,
    workspace,
    lockNavbar,
  }),
  {
    openPasswordUpdateModal,
    openOnboardingModalWithScreen,
  },
)(NavProfile);
NavProfile = withRouter(NavProfile);
export default NavProfile;
