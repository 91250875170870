import React, { Component } from 'react';

import AssignDrawer from 'app/components/AssignDrawer';
import AssignMessage from 'app/components/AssignDrawer/AssignMessage';
import AttachmentPreviewModal from 'app/components/AttachmentPreview/AttachmentPreviewModal';
import CommentShare from 'app/components/CommentShareView';
import EditImage from 'app/components/BoardView/EditImage';
import InfoModal from 'app/components/InfoModal';
import NavSearchModal from 'app/components/NavSearchModal';
import OnboardingModals from 'app/components/OnboardingModals';
import PatientForm from 'app/components/PatientForm';
import PlayerModal from 'app/components/PlayerModal';
import PoliciesModal from 'app/components/PoliciesModal';
import ProfileDetailsConfirmationView from 'app/components/ProfileDetailsConfirmationView';
import ShareModal from 'app/components/ShareModal';
import ReportObjectModal from 'app/components/ReportObject';
import UpdatePasswordModal from 'app/components/AllModalsContainer/UpdatePasswordModal';
import TaskModal from 'app/components/TaskModal';
import TaskPreviewModal from 'app/components/TaskPreviewModal';
import GroupChatModal from 'app/components/GroupMessenger/GroupChatModal';
import FolderModal from 'app/components/FolderModal';
import PartnerClinicModal from 'app/components/AllModalsContainer/PartnerClinicModal';

export default class AllModalsContainer extends Component {
  render() {
    return (
      <>
        <AssignDrawer />
        <AssignMessage />
        <AttachmentPreviewModal />
        <CommentShare />
        <EditImage />
        <GroupChatModal />
        <FolderModal />
        <InfoModal />
        <NavSearchModal />
        <OnboardingModals />
        <PoliciesModal />
        <ShareModal />
        <ReportObjectModal />
        <TaskModal />
        <PartnerClinicModal />
        <TaskPreviewModal />
        <UpdatePasswordModal />
        <PatientForm />
        <PlayerModal />
        <ProfileDetailsConfirmationView />
      </>
    );
  }
}
