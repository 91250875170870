import React, { Component } from 'react';
import { withApollo } from '@apollo/client/react/hoc';
import { connect } from 'react-redux';

import { updateCurrentUserGraph } from 'app/actions/authentication';

import AttachmentsInDiscussion from 'app/components/Widgets/AttachmentsInDiscussion';
import BackToTop from 'app/components/Shared/BackToTop';
import ClinicTeamWithUsers from 'app/components/Widgets/ClinicTeamWithUsers';
import PartnerSpaceWithMembers from 'app/components/Widgets/PartnerSpaceWithMembers';
import PatientAndResponseParties from 'app/components/Widgets/PatientAndResponseParties';
import PopularHashTags from 'app/components/Widgets/PopularHashTags';
import RecentDiscussions from 'app/components/CommentSection/RecentDiscussions';
import RecentlyOnlineMembersWidget from 'app/components/Widgets/RecentlyOnlineMembersWidget';
import TaskWidget from 'app/components/Widgets/TaskWidget';
import TipOfTheDay from 'app/components/Shared/Tip';
import WorkspaceInformationWidget from 'app/components/Shared/WorkspaceInformationWidget';

import { MESSAGE_FLOATER_HEIGHT } from 'app/constants';

import { getStickyValue } from 'app/utils/entitiesHelper';
import { isCareSpace, isClinicSpace } from 'app/utils/spaceHelper';
import { clone, first, slice } from 'app/utils/osLodash';
import { possibleBroadcastMentionGroups } from 'app/utils/widgetHelper';
import RelatedTaskWidget from 'app/components/Task/RelatedTaskWidget';
import TreatmentProgress from 'app/components/TreatmentProgress';
import LabelWidget from 'app/components/Widgets/LabelWidget';
import TeamWidget from 'app/components/Widgets/TeamWidget';
import StatusWidget from 'app/components/Widgets/StatusWidget';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import { withWorkspaceIdentifier } from 'app/utils/Workspace/generalHelper';

const BLOCKS_REQUIRED = {
  careExploreNoData: [],
  careSpaceDetail: [
    'StatusWidget',
    'PatientAndResponseParties',
    'TreatmentProgress',
    'RelatedTaskWidget',
    'PopularHashTags',
  ],
  // 'carePatientView': ['PersonCareSpaces', 'PersonRelatedPatients', 'PersonResponseParties', 'PersonClinicTeam'],
  explore: ['PopularHashTags'],
  'explore-workspace': [
    'PopularHashTags',
    'AttachmentsInDiscussion',
    'TipOfTheDay',
  ],
  exploreCareWorkspace: [
    'PopularHashTags',
    'AttachmentsInDiscussion',
    'TipOfTheDay',
  ],
  tasksManager: ['LabelWidget', 'TeamWidget'],
  myTasksManager: ['LabelWidget'],
  teamTasksManager: ['LabelWidget', 'TeamWidget'],
  boardTasksManager: [],
  'space-detail-open-discussion': ['PopularHashTags'],
  'space-detail-close-discussion': ['PopularHashTags'],
  'space-detail-open-information': [],
  'space-detail-close-information': [],
  'space-detail-information-workspace': [],
  'space-detail-information-guest': ['GettingStartedBlock'],
  'space-detail-information-guest-clinic': ['ClinicTeamWithUsers'],
  'space-detail-information-guest-company': ['GettingStartedBlock'],
  'space-detail-information-guest-care': ['ClinicTeamWithUsers'],
  'space-detail-information-care': ['ClinicTeamWithUsers'],
  'space-detail-information-clinic': ['ClinicTeamWithUsers'],
  'space-detail-discussion-care': [
    'PatientAndResponseParties',
    'PopularHashTags',
    'ClinicTeamWithUsers',
  ],
  'space-detail-discussion-care-patientview': [
    'PatientAndResponseParties',
    'ClinicTeamWithUsers',
    'TreatmentProgress',
    'PopularHashTags',
  ],
  'space-detail-discussion-clinic': ['ClinicTeamWithUsers'],
  'space-detail-discussion-company': ['PopularHashTags'],
  'user-detail': [],
  'pulse-detail': ['PopularHashTags'],
  'account-settings': ['GettingStartedBlock'],
  teamWindow: [],
  teamGroupMessenger: [
    'TipOfTheDay',
    'RelatedTaskWidget',
    'AttachmentsInDiscussion',
  ],
  teamDirectMessenger: ['TipOfTheDay', 'AttachmentsInDiscussion'],
  partners: ['RelatedTaskWidget', 'PartnerSpaceWithMembers'],
  noPartners: ['RelatedTaskWidget', 'PartnerSpaceWithMembers'],
};

// Adding source here will ensure that widget gets loaded after fetching source object
const WIDGET_SOURCE_OBJECT_REQUIRED = {
  careSpaceDetail: [
    'StatusWidget',
    'PatientAndResponseParties',
    'PopularHashTags',
    'RelatedTaskWidget',
  ],
  exploreCareWorkspace: ['AttachmentsInDiscussion'],
  teamDirectMessenger: ['TipOfTheDay', 'AttachmentsInDiscussion'],
  teamGroupMessenger: [
    'TipOfTheDay',
    'RelatedTaskWidget',
    'AttachmentsInDiscussion',
  ],
};
const EXCLUDE_IN_SMALL = [
  'StatusWidget',
  'PatientAndResponseParties',
  'PopularHashTags',
  'RelatedTaskWidget',
  'ClinicTeamWithUsers',
  'TipOfTheDay',
  'TreatmentProgress',
  'AttachmentsInDiscussion',
  'LabelWidget',
  'TeamWidget',
];

class RightSidebar extends Component {
  state = {
    isSticky: false,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.handleSticky);
    setTimeout(() => {
      this.setStickyRefContainerName();
    }, 0);
  }

  componentDidUpdate(prevProps) {
    let loadingCompleted =
      prevProps.data &&
      prevProps.data.loading &&
      this.props.data &&
      !this.props.data.loading;

    if (loadingCompleted)
      this.props.updateCurrentUserGraph(this.props.data.user);

    if (
      loadingCompleted ||
      (this.props.device.ipad &&
        prevProps.device.portrait !== this.props.device.portrait)
    )
      setTimeout(() => {
        this.setStickyRefContainerName();
      }, 0);

    if (
      this.getSource() === 'careSpaceDetail' &&
      prevProps.device.width < 1440 &&
      this.props.device.width >= 1440
    ) {
      this.props.navigate(
        withWorkspaceIdentifier(
          `/spaces/${this.props.options.widgetSourceObject.nice_id}/activity/all`,
        ),
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleSticky, false);
  }

  widgetSourceObject() {
    return this.props.options && this.props.options.widgetSourceObject;
  }

  setStickyRefContainerName = () => {
    if (this.isStickyRequiredOnDevice() && this.mainContainer) {
      let pulseHeight = this.mainContainer.clientHeight;
      let viewportHeight =
        window.innerHeight -
        this.mainContainer.offsetTop -
        MESSAGE_FLOATER_HEIGHT;
      this.mainContainerOffsetTop = this.mainContainer.offsetTop;
      if (pulseHeight < viewportHeight) {
        this.setState({ fixedContainerRefName: 'threeBlockContainer' });
        this.stickyContainerOffsetTop = window
          .$('.three-block-sticky')
          .offset().top;
      } else {
        this.setState({ fixedContainerRefName: 'twoBlockContainer' });
        this.stickyContainerOffsetTop = window
          .$('.two-block-sticky')
          .offset().top;
      }
    }
  };

  handleSticky = (e) => {
    let isSticky =
      window.scrollY >=
      this.stickyContainerOffsetTop -
        getStickyValue(this.props.source, this.props.isAuthorOrCollaborator)
          .marginHeaderNavValue;

    this.setState({ isSticky });
  };

  isStickyRequiredOnDevice = () => {
    let { ipad, portrait, mobileDevice } = this.props.device;
    return !(mobileDevice || (ipad && portrait));
  };

  isStickyRequired() {
    return this.isStickyRequiredOnDevice() && this.state.isSticky;
  }

  getStyles() {
    let top = 0;
    if (this.isStickyRequired() && this[this.state.fixedContainerRefName])
      top =
        -1 *
          (this.mainContainer.clientHeight -
            this[this.state.fixedContainerRefName].clientHeight) +
        'px';
    return { top };
  }

  getSource() {
    return this.props.source;
  }

  getSourcePageClass() {
    return this.getSource() || '';
  }

  getMainContainerStyles() {
    return this.isStickyRequired()
      ? {
          top: getStickyValue(
            this.props.source,
            this.props.isAuthorOrCollaborator,
          ).stickyTop,
        }
      : {};
  }

  isSpaceDiscussionsActive() {
    return (
      (this.getSource().includes('space-detail') &&
        this.getSource().includes('discussion')) ||
      this.getSource().includes('case-detail') ||
      this.getSource().includes('careSpaceDetail')
    );
  }

  isTipOfTheDayWidgetRequired() {
    let widgetSourceObject = this.widgetSourceObject();
    return !isCareSpace(widgetSourceObject);
  }

  getRequiredBlocks() {
    let { device } = this.props,
      source = this.getSource(),
      blocks = clone(BLOCKS_REQUIRED[source]),
      widgetSourceObject = this.widgetSourceObject(),
      canHaveBroadcastMentions =
        possibleBroadcastMentionGroups(widgetSourceObject?.mention_groups)
          .length > 0;
    if (device.ipadPortrait || device.width < 1440) {
      blocks = blocks?.filter((item) => !EXCLUDE_IN_SMALL.includes(item)) || [];
    }

    if (
      blocks &&
      this.isTipOfTheDayWidgetRequired() &&
      canHaveBroadcastMentions &&
      this.isSpaceDiscussionsActive()
    ) {
      blocks.unshift('TipOfTheDay');
    }
    return blocks;
  }

  getUsersToConnectWithCount() {
    let { ipad, mobileDevice, portrait } = this.props.device;
    return (ipad && portrait) || mobileDevice ? 3 : 5;
  }

  popularHashTagsRequired() {
    return (
      (!this.isSpaceDiscussionsActive() ||
        this.widgetSourceObject().comments_count > 0) &&
      !(this.isSpaceDiscussionsActive() && this.props.device.mobileDevice)
    );
  }

  widgetSourceObjectRequired(block) {
    const BLOCKS = WIDGET_SOURCE_OBJECT_REQUIRED[this.getSource()] || [];
    return (
      (BLOCKS.includes(block) && this.widgetSourceObject()) ||
      !BLOCKS.includes(block)
    );
  }

  renderBlock(block) {
    let commonProps = {
        key: block,
        size: 'extra-small',
        cardGridIdentifier: this.props.cardGridIdentifier,
        isGuest: !this.props.currentUser.graph,
        onContentLoad: this.setStickyRefContainerName,
      },
      sourceObject = this.widgetSourceObject();

    if (this.widgetSourceObjectRequired(block))
      switch (block) {
        case 'AttachmentsInDiscussion':
          return (
            <AttachmentsInDiscussion
              {...commonProps}
              widgetSourceObject={sourceObject}
              source={this.getSource()}
            />
          );
        case 'PartnerSpaceWithMembers':
          return <PartnerSpaceWithMembers {...commonProps} />;
        case 'ClinicTeamWithUsers':
          return (
            <ClinicTeamWithUsers
              {...commonProps}
              widgetSourceObject={
                isClinicSpace(sourceObject)
                  ? sourceObject
                  : sourceObject?.owner?.clinic
              }
            />
          );
        case 'BackToTop':
          return <BackToTop {...commonProps} />;
        case 'RecentlyOnlineMembersWidget':
          return (
            <RecentlyOnlineMembersWidget
              {...commonProps}
              widgetSourceObject={sourceObject}
            />
          );
        case 'GettingStartedBlock':
          return null;
        // return <GettingStartedBlock {...commonProps} additionalClass='pulse-get-started' />
        case 'WorkspaceInformationWidget':
          return <WorkspaceInformationWidget {...commonProps} />;
        case 'PatientAndResponseParties':
          return (
            <PatientAndResponseParties
              widgetSourceObject={sourceObject}
              {...commonProps}
            />
          );
        case 'PopularHashTags':
          return (
            this.popularHashTagsRequired() && (
              <PopularHashTags
                source={this.getSource()}
                entity={this.isSpaceDiscussionsActive() ? sourceObject : null}
              />
            )
          );
        case 'TaskWidget':
          return <TaskWidget {...commonProps} />;
        case 'TipOfTheDay':
          return (
            <TipOfTheDay
              widgetSourceObject={sourceObject}
              source={this.getSource()}
            />
          );
        case 'RecentDiscussions':
          return <RecentDiscussions widgetSourceObject={sourceObject} />;
        case 'RelatedTaskWidget':
          return (
            <RelatedTaskWidget
              widgetSourceObject={sourceObject}
              {...commonProps}
            />
          );
        case 'TreatmentProgress':
          return (
            sourceObject && (
              <TreatmentProgress
                widgetSourceObject={sourceObject}
                {...commonProps}
              />
            )
          );
        case 'LabelWidget':
          return <LabelWidget />;
        case 'TeamWidget':
          return <TeamWidget />;
        case 'StatusWidget':
          return (
            <StatusWidget widgetSourceObject={sourceObject} {...commonProps} />
          );
        default:
          return null;
      }
  }

  renderStickyBlocksStructure(firstBlockName, restOfBlocks) {
    let threeBlockStickyClass = '';
    if (
      !(
        this.getSource().includes('teamGroupMessenger') ||
        this.getSource().includes('teamDirectMessenger')
      )
    ) {
      threeBlockStickyClass = this.getStyles();
    }

    return (
      <div
        className='three-block-sticky'
        ref={(elm) => (this.threeBlockContainer = elm)}
        style={{ ...threeBlockStickyClass }}>
        {this.renderBlock(firstBlockName)}
        <div
          className='two-block-sticky'
          ref={(elm) => (this.twoBlockContainer = elm)}>
          {restOfBlocks.map((block) => this.renderBlock(block))}
        </div>
      </div>
    );
  }

  renderSimpleBlockStructure(firstBlockName, restOfBlocks) {
    return (
      <div>
        {this.renderBlock(firstBlockName)}
        {restOfBlocks.map((block) => this.renderBlock(block))}
      </div>
    );
  }

  renderBlocks() {
    let requiredBlocks = this.getRequiredBlocks(),
      firstBlockName = first(requiredBlocks),
      restOfBlocks = slice(requiredBlocks, 1);

    return this.isStickyRequiredOnDevice()
      ? this.renderStickyBlocksStructure(firstBlockName, restOfBlocks)
      : this.renderSimpleBlockStructure(firstBlockName, restOfBlocks);
  }

  render() {
    let className = 'pulse-sidebar pulse-right-sidebar ';

    className += this.isStickyRequired() ? 'sidebar_fixed ' : '';
    className += this.getSourcePageClass();

    if (this.props.sideBarClass) {
      className += this.props.sideBarClass;
    }

    return (
      <article
        className={className}
        ref={(elm) => (this.mainContainer = elm)}
        style={{ ...this.getMainContainerStyles() }}>
        {this.renderBlocks()}
      </article>
    );
  }
}

RightSidebar = connect(({ currentUser, device }) => ({ currentUser, device }), {
  updateCurrentUserGraph,
})(RightSidebar);
RightSidebar = withApollo(RightSidebar);
RightSidebar = withRouter(RightSidebar);

export default RightSidebar;
