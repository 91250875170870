import React, { Component } from 'react';
import { connect } from 'react-redux';

import Icon from 'app/components/Svg';
import NoEntityFound from 'app/components/NoEntityFound';
import OrthoIcon from 'app/components/Shared/OrthoIcon';

import { translate } from 'app/actions/flashMessage';

const DEFAULT_MESSAGE = 'No results found.';

class NoEntitiesFound extends Component {
  constructor(props) {
    super(props);
    this.selfMessages = {
      board: 'No spaces were found.',
      case: 'No cases were found.',
      comparison: 'No comparisons were found.',
      connections: 'No connections were found.',
      course: 'No courses were found.',
      course_subscription: 'You have not subscribed to any course.',
      folder: 'No folders were found.',
      following: "You aren't following any items or doctors.",
      followers: 'No followers were found.',
      invoice: 'No Transaction Yet.',
      note: 'No notes were found.',
      notification: 'No notifications so far',
      post: 'No posts were found.',
      tool: 'No tools were found.',
      user: 'No users were found.',
      member: 'No members were found.',
    };

    this.iconMapper = {
      pending_connections: 'link',
    };
  }

  getKey() {
    switch (this.props.type) {
      case 'cases':
        return 'case';
      case 'boards':
        return 'board';
      case 'folder':
        return 'folder';
      default:
        return this.props.type;
    }
  }

  isPatientSearchModal() {
    return this.props.selectionMode && this.props.type === 'contacts';
  }

  getMessage(key) {
    let message;
    if (this.props.type === 'user' && this.props.filter === 'following') {
      message = "You aren't following any doctors.";
    } else if (this.props.searchText) {
      if (this.isPatientSearchModal()) {
        message = translate(
          'RESULTS_NOT_FOUND_ADD_NEW',
          { searchInput: this.props.searchText },
          true,
          { connector: '' },
        );
      } else {
        message = translate('RESULTS_NOT_FOUND', {
          searchInput: this.props.searchText,
        });
      }
    } else {
      message = this.selfMessages[key] || DEFAULT_MESSAGE;
    }
    if (
      this.props.currentUser.graph &&
      this.props.currentUser.graph.is_general_user
    ) {
      return message.replace(/doctors/gi, 'members');
    } else {
      return message;
    }
  }

  getSVGKey(key) {
    switch (key) {
      case 'board':
        return 'noSpaces';
      case 'case':
        return 'noCases';
      case 'comparison':
        return 'noComparisons';
      case 'connections':
        return 'noConnections';
      case 'course':
      case 'folder':
        return 'cannotOpen';
      case 'course_subscription':
        return 'course';
      case 'followers':
        return 'cannotOpen';
      case 'following':
        return 'noFollowing';
      case 'note':
        return 'noNotes';
      case 'post':
        return 'noPosts';
      case 'tool':
        return 'noTools';
      case 'user':
        return 'noUsers';
      case 'member':
        return 'noUsers';
      default:
        return 'noResults';
    }
  }

  getFilterKey() {
    switch (this.props.filter) {
      case 'accepted_connections':
        return 'accepted_connections';
      case 'pending_connections':
        return 'pending_connections';
      case 'following':
        return 'following';
      case 'followers':
        return 'followers';
      case 'note':
        return 'note';
      case 'connections':
        return 'connections';
      case 'course_subscriptions':
        return 'course_subscription';
      case 'subscription_details':
        return 'invoice';
      default:
        return this.getKey();
    }
  }

  isAtCaseWithMobile() {
    return this.getFilterKey() === 'case' && this.props.device.mobileDevice;
  }

  actionNotRequired() {
    return (
      this.getFilterKey() === 'note' ||
      this.getFilterKey() === 'followers' ||
      this.isAtCaseWithMobile() ||
      this.props.noActionRequired
    );
  }

  isCurrentUserPage() {
    return (
      this.props.user && this.props.user.id === this.props.currentUser.graph.id
    );
  }

  render() {
    let key = this.getFilterKey(),
      iconName = this.isPatientSearchModal() ? 'noCases' : '';
    return (
      <div className={`no-cases-block ${this.props.type}`}>
        <div className='no-cases-image-block'>
          <OrthoIcon
            name={this.iconMapper[key]}
            defaultClass='icon-no-content'
          />
        </div>
        <div className='no-svg-img'>
          <Icon name={iconName || this.getSVGKey(key)} />
        </div>
        <div className='no-cases-message-block'>{this.getMessage(key)}</div>
        <div className='no-button'>
          {' '}
          {!this.actionNotRequired() && this.isCurrentUserPage() && (
            <NoEntityFound entity={key} obj={this.props.user} />
          )}{' '}
        </div>
      </div>
    );
  }
}

NoEntitiesFound = connect(
  ({ currentUser, device }) => ({ currentUser, device }),
  {},
)(NoEntitiesFound);

export default NoEntitiesFound;
