import React from 'react';

import { Navigate, Routes, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { useEffect } from 'react';

import ConfirmEmail from 'app/components/ConfirmEmail';
import EmailSourceUrl from 'app/components/EmailSourceUrl';
import EmailSourceWorkspaceView from 'app/components/EmailSourceUrl/WorkspaceView';
import EmptyLayout from 'app/components/Layouts/EmptyLayout';
import ForgotPassword from 'app/components/ForgotPassword';
import ForgotPasswordWorkspaceView from 'app/components/ForgotPassword/WorkspaceView';
import GuestLayout from 'app/components/Layouts/GuestLayout';
import LoginWorkspaceView from 'app/components/LoginView/LoginWorkspaceView';
import LoginView from 'app/components/LoginView';
import MagicLinkLogin from 'app/components/MagicLinkLogin';
import PatientOnboardView from 'app/components/CareSpace/Onboard/PatientOnboardView';
import PoliciesPageView from 'app/components/PoliciesPageView';
import ResetPassword from 'app/components/LoginView/ResetPassword';
import RouteReplacer from 'app/routes/Shared/RouteReplacer';
import SignupWorkspaceView from 'app/components/CareSpace/Signup/SignupWorkspaceView';
import SignupScreen from 'app/components/SignupScreen';
import SignupView from 'app/components/CareSpace/Signup';
import PartnerInvitationContainer from 'app/components/Partners/Invitation/Container';
import SystemUpgrade from 'app/components/SystemUpgrade';

import { loader as queryLoader } from 'graphql.macro';
import withGraphqlRequests from 'app/components/HOC/withGraphqlRequests';

import { setGuestWorkspaceOwnerData } from 'app/actions/workspace';

const GUEST_WORKSPACE_OWNER_QUERY = queryLoader(
  'app/graphql/Workspace/Queries/guestWorkspaceOwner.gql',
);

const UnauthenticatedRoutes = (props) => {
  const location = useLocation(),
    { guestWorkspaceOwner } = props.data,
    { setGuestWorkspaceOwnerData } = props;

  useEffect(() => {
    function getPageClass(location) {
      let pathnames = location.pathname.split('/'),
        pathname = pathnames[1];
      return pathname;
    }
    window.$('body').removeClass();
    let className = getPageClass(location);
    className = className || 'landing-page';
    window.$('body').addClass(className);
  }, [location]);

  useEffect(() => {
    setGuestWorkspaceOwnerData(guestWorkspaceOwner);
  }, [setGuestWorkspaceOwnerData, guestWorkspaceOwner]);

  return (
    <Routes>
      <Route element={<GuestLayout />}>
        <Route path='forgot_password' element={<ForgotPassword />} />
        <Route path='login' element={<LoginView />} />
        <Route path='care/login' element={<LoginView />} />
        <Route
          path='/:identifier/forgot_password'
          element={<ForgotPasswordWorkspaceView unauthenticated={true} />}
        />
        <Route
          path='/:identifier/login'
          element={<LoginWorkspaceView unauthenticated={true} />}
        />
        <Route
          path='/:identifier/source_email/:url'
          element={<EmailSourceWorkspaceView unauthenticated={true} />}
        />
        <Route
          path='/:identifier/signup/:token'
          element={<SignupWorkspaceView unauthenticated={true} />}
        />
        <Route
          path='/:identifier/onboard/:token'
          element={<PatientOnboardView unauthenticated={true} />}
        />
        <Route
          path='/:identifier/clinic-onboard/:token'
          element={<PartnerInvitationContainer unauthenticated={true} />}
        />
        <Route path='/signup/:token' element={<SignupView />} />
        <Route
          path='/spaces/:nice_id/:identifier/signup/:token'
          element={<RouteReplacer from='/spaces/:nice_id' to='' />}
        />
        <Route path='/source_email/:url' element={<EmailSourceUrl />} />
        <Route path='/confirm_email/:token' element={<ConfirmEmail />} />
        <Route path='/reset_password' element={<ResetPassword />} />
        <Route path='/policies/:type' element={<PoliciesPageView />} />
        <Route path='/magic_link/:token' element={<MagicLinkLogin />} />
        <Route
          path='/:identifier/space_invite/:token'
          element={<SignupScreen />}
        />
      </Route>
      <Route element={<EmptyLayout />}>
        <Route path='/system_upgrade' element={<SystemUpgrade />} />
      </Route>
      <Route path='*' element={<Navigate to='/login' />} />
    </Routes>
  );
};

const UnauthenticatedRoutesContainer = withGraphqlRequests(
  GUEST_WORKSPACE_OWNER_QUERY,
  {
    options: (props) => ({
      fetchPolicy: 'network-only',
    }),
  },
  {
    objectIdentifier: 'guestWorkspaceOwner',
  },
)(UnauthenticatedRoutes);

export default connect(null, { setGuestWorkspaceOwnerData })(
  UnauthenticatedRoutesContainer,
);
