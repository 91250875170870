import React from 'react';
import { connect } from 'react-redux';
import { graphql } from '@apollo/client/react/hoc';

import { getSearchParamValue } from 'app/utils/urlHelper';
import { loader as queryLoader } from 'graphql.macro';
import { Form, Field } from 'react-final-form';
import { withRouter } from 'app/components/HOC/Router/withRouter';

import AbstractCard from './AbstractCard';
import StoreUpdater from 'app/services/StoreUpdater';
import OsField from '../OsField';

import { fieldRequired } from 'app/utils/validationHelper';
import { STICKY_WIDGET_LAYOUT_REQUIRED_COUNT } from 'app/components/FoldersView/constants';
import { updateCurrentUserGraph } from 'app/actions/authentication';
import { log } from 'logrocket';

const UPDATE_FOLDER_MUTATION = queryLoader('app/graphql/UpdateFolder.gql');

class NewFolderCard extends AbstractCard {
  state = { toggleProgress: false };

  countOfItemsAdded() {
    return this.props.obj.links_count
      ? `${this.props.obj.links_count} added content`
      : '';
  }

  myFoldersCount() {
    return this.props.currentUser.graph.visible_folders_count;
  }

  stickyWidgetRequired() {
    return (
      !this.props.device.mobileDevice &&
      this.myFoldersCount() > STICKY_WIDGET_LAYOUT_REQUIRED_COUNT
    );
  }

  getCurrentSortFilterKey() {
    return getSearchParamValue('sort') || 'recently_updated';
  }

  onSubmit = (values) => {
    const user = this.props.currentUser?.graph;
    if (!this.state.toggleProgress) {
      this.setState({ toggleProgress: true });
      this.props
        .updateFolderMutation({
          variables: {
            ...values,
            owner_id: this.props.space?.id,
            owner_type: this.props.space?.__typename,
          },
          optimisticResponse: () => {
            return {
              createFolder: {
                id: null,
                success: true,
                entity: {
                  category: 'close',
                  category_name: 'Sub Space',
                  category_type: 'folder',
                  display_name: values.name,
                  frontend_url: '',
                  id: '0',
                  image: '',
                  is_author_or_collaborator: true,
                  is_author_or_editor: true,
                  is_author_or_member: true,
                  links_count: 0,
                  member_request_status: null,
                  member_requested_by_author: null,
                  name: values.name,
                  nice_id: '',
                  objective: 'normal',
                  owner: null,
                  restricted: false,
                  type: 'Content',
                  user: {
                    id: this.props.currentUser.graph.id,
                    avatar: null,
                    degree_list: [],
                    first_name: user.first_name,
                    full_name: user.full_name,
                    initials: user.initials,
                    large_avatar: null,
                    last_name: user.last_name,
                    name: user.name,
                    nice_id: user.nice_id,
                    role_type: '',
                    is_support_user: false,
                    email: user.email,
                    __typename: 'User',
                  },
                  workflow_state: 'published',
                  __typename: 'FolderSpace',
                },
              },
            };
          },
          update: (
            proxy,
            {
              data: {
                createFolder: { entity },
              },
            },
          ) => {
            let infiniteScroll = this.stickyWidgetRequired(),
              idToBeRemoved = entity.id === '0' ? null : '0',
              sortFilter = this.getCurrentSortFilterKey();
            StoreUpdater.addRecordInRecordPager(
              { ...entity },
              {
                queryType: 'SPACE_DETAILS',
                loadingCards: true,
                type: 'MyFolders',
                limit: 10,
                infiniteScroll: infiniteScroll,
                sortQuery: sortFilter,
                cardSize: 'extra-small',
                twoPerRow: infiniteScroll,
                insertAtLast: false,
                forcedTextQuery: '',
                idToBeRemoved,
              },
            );
            if (entity.id === '0') this.props.toggleCreate();
          },
        })
        .then(({ data }) => {
          this.props.updateCurrentUserGraph({
            visible_folders_count:
              this.props.currentUser.graph.visible_folders_count + 1,
          });
          this.setState({ toggleProgress: false });
        });
    }
  };

  renderHeaderImage() {
    return (
      <figure className='card-v2-hero'>
        <div className='img-parent'>
          <span className='hero-image-overlay'></span>
        </div>
      </figure>
    );
  }

  render() {
    let footerClass = 'without-media';

    return (
      <>
        <div className={'os-card-v2  extra-small-min-card selection-mode'}>
          <div
            className={
              'space-v2-card  os-sm-space private-space  space-without-action add-new-card'
            }>
            <span
              className='os-close-link ifill-os-clear'
              onClick={this.props.toggleCreate}></span>

            <div className='card-v2-header'>{this.renderHeaderImage()}</div>

            <article className={footerClass}>
              <Form
                onSubmit={this.onSubmit}
                render={(props) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className='space-user-info '>
                        <Field
                          name='name'
                          type='text'
                          osType='input'
                          component={OsField}
                          placeholder='New Folder Name'
                          validate={fieldRequired}
                          formGroupExtraClass='mb-0 w-100 '
                          autoFocus
                          onBlur={props.handleSubmit}
                        />
                      </div>
                    </form>
                  );
                }}
              />
            </article>
          </div>
        </div>
      </>
    );
  }
}

NewFolderCard = withRouter(NewFolderCard);
NewFolderCard = connect(
  ({ currentUser, systemConfig, device }) => ({
    currentUser,
    systemConfig,
    device,
  }),
  { updateCurrentUserGraph },
)(NewFolderCard);
NewFolderCard = graphql(UPDATE_FOLDER_MUTATION, {
  name: 'updateFolderMutation',
})(NewFolderCard);
export default NewFolderCard;
