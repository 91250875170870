import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { flashSuccess } from 'app/actions/flashMessage';
import { openInfoModal } from 'app/actions/infoModal';
import { omit } from 'app/utils/osLodash';
import EventTracker from 'app/services/EventTracker';
import { closeTeamSpaceModal } from 'app/actions/teamSpaceModal';
import { updateSpaceState } from 'app/actions/updateSpaceState';
import { isSpaceArchived } from 'app/utils/spaceHelper';
import defaultUploadThumb from 'app/images/svg-thumbnail/folder-empty.png';
import useLocalFileUpload from 'app/hooks/useLocalFileUpload';
import StoreUpdater from 'app/services/StoreUpdater';
import { GROUPS_BATCH_SIZE } from 'app/constants';
import {
  GROUP_ADDED_SUCCESSFULLY,
  GROUP_ARCHIVED_SUCCESSFULLY,
  GROUP_NOTIFICATIONS_MUTED_SUCCESSFULLY,
  GROUP_NOTIFICATIONS_UNMUTED_SUCCESSFULLY,
  GROUP_UPDATED_SUCCESSFULLY,
  LEFT_GROUP_SUCCESSFULLY,
} from 'app/components/GroupMessenger/constants';

const TEAMSPACE_BY_ID = queryLoader(
  'app/graphql/queries/Spaces/Types/TeamSpace.gql',
);
const EDIT_SPACE_QUERY = queryLoader('app/graphql/EditBoard.gql');
const UPDATE_GROUP_MUTATION = queryLoader('app/graphql/UpdateGroup.gql');
const SPACE_INVITATION_RESPONSE = queryLoader(
  'app/graphql/SpaceMemberRequestResponse.gql',
);
const GROUP_SIDEBAR_LIST = queryLoader(
  'app/graphql/queries/Spaces/GroupSidebarCollection.gql',
);
const FOLLOW_BOARD_MUTATION = queryLoader('app/graphql/FollowBoard.gql');
const UNFOLLOW_BOARD_MUTATION = queryLoader('app/graphql/UnfollowBoard.gql');

const useGroupModal = (props) => {
  const navigate = useNavigate();
  const { data: workspace } = useSelector((state) => state.workspace);
  const teamSpaceModal = useSelector((state) => state.teamSpaceModal);
  const currentUser = useSelector((state) => state.currentUser);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [leaveGroupMutation] = useMutation(SPACE_INVITATION_RESPONSE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);
  const [isLeavingGroup, setIsLeavingGroup] = useState(false);
  const [groupAttachmentId, setGroupAttachmentId] = useState(null);
  const [selectedGroupImageUrl, setSelectedGroupImageUrl] =
    useState(defaultUploadThumb);
  const {
    uploadFile,
    assetDetails,
    isLoading: isUploading,
    percentageCompleted: uploadPercentage,
    resetPercentage,
  } = useLocalFileUpload();

  const dispatch = useDispatch();
  const getInitialFormState = () => ({
    links: [],
    new_member_emails: [],
    new_member_ids: [],
  });
  const [initialValues, setInitialValues] = useState(getInitialFormState);
  const [groupFormData, setGroupFormData] = useState({});
  // Mutation hooks
  const [updateTeamSpaceMutation] = useMutation(UPDATE_GROUP_MUTATION);
  // Follow/UnFollow
  const [followGroupMutation] = useMutation(FOLLOW_BOARD_MUTATION);
  const [unfollowGroupMutation] = useMutation(UNFOLLOW_BOARD_MUTATION);

  const { data: newGroupData } = useQuery(EDIT_SPACE_QUERY, {
    skip: !workspace || !teamSpaceModal.canUpdateGroup,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: workspace?.nice_id,
      isNew: true,
      isNewSubspace: true,
    },
  });

  const { data: teamSpaceDetails } = useQuery(TEAMSPACE_BY_ID, {
    skip: !teamSpaceModal.spaceId,
    fetchPolicy: 'cache-and-network',
    variables: {
      id: teamSpaceModal.spaceId,
    },
  });

  // File upload status
  useEffect(() => {
    setIsSubmitDisabled(isUploading);
  }, [isUploading]);

  // Load data required to load create group form
  useEffect(() => {
    if (newGroupData) setGroupFormData(newGroupData);
  }, [newGroupData]);

  // Asset details after file upload
  useEffect(() => {
    if (assetDetails) {
      setGroupAttachmentId(assetDetails.id);
      setSelectedGroupImageUrl(assetDetails.preview_url);
    }
  }, [assetDetails]);

  useEffect(() => {
    if (teamSpaceDetails) {
      setInitialValues((prevState) => ({
        ...prevState,
        ...teamSpaceDetails.board,
      }));
      if (teamSpaceDetails.board.image) {
        setSelectedGroupImageUrl(teamSpaceDetails.board.image);
      }
    }
  }, [teamSpaceDetails]);

  const sanitizeParams = (values) => {
    values.links = [];
    values.owner_type = groupFormData.parent_board.__typename;
    values.owner_id = groupFormData.parent_board.id;
    return values;
  };

  const closeModal = () => {
    setIsSubmitting(false);
    setIsSubmitDisabled(false);
    setGroupAttachmentId(null);
    setSelectedGroupImageUrl(defaultUploadThumb);
    setInitialValues(getInitialFormState());
    resetPercentage();
    dispatch(closeTeamSpaceModal({ type: teamSpaceModal.type }));
  };

  const isEditView = () => {
    return teamSpaceModal.spaceId;
  };

  const showDeleteModalView = (viewModal) => {
    setShowDeleteModal((prevState) => viewModal);
  };

  const isOwner = (space) => {
    return +(space || props.data.board).user.id === +currentUser.graph.id;
  };

  const canUpdateSpace = (space) => {
    if (space) {
      return currentUser.ability.can('update', 'board', {
        canEdit: isOwner(space),
      });
    }
  };

  const canCreateSpace = () => {
    return currentUser.ability.can('create', 'board');
  };

  // Handle image selected from form
  const onGroupBannerChange = (localFile) => {
    setIsSubmitDisabled(true);
    uploadFile(localFile);
  };

  const spaceObject = () => {
    let space = workspace || {};
    space.links = [];
    if (!isEditView()) {
      let { parent_board } = groupFormData;
      space.owner = parent_board;
      space.information = parent_board.information;
      space.category = parent_board.category;
    }
    return space;
  };

  const isSubmitPermitted = () => {
    const spaceData = spaceObject();
    return isEditView() ? canUpdateSpace(spaceData) : canCreateSpace();
  };

  const afterArchived = (space) => {
    setIsArchiving(false);
    if (!space) {
      navigate(`/${workspace.identifier}/team`);
    } else if (isSpaceArchived(space)) {
      flashSuccess(GROUP_ARCHIVED_SUCCESSFULLY, false)(dispatch);
    } else {
      dispatch(
        openInfoModal('space', 'group_unarchive', {
          contentInterpolations: { name: space.name },
        }),
      );
    }
    closeModal();
  };

  const archiveSpace = async (entity) => {
    setIsArchiving(true);
    dispatch(
      updateSpaceState({
        archive: !isSpaceArchived(entity),
        space: { id: entity.id },
        afterSuccessAction: afterArchived,
      }),
    );
  };

  const leaveGroup = async (entity) => {
    setIsLeavingGroup(true);
    let userId = currentUser.graph.id;
    await leaveGroupMutation({
      variables: {
        userId: userId,
        email: null,
        spaceId: entity.nice_id,
        accept_response: false,
        spaceCardSummaryRequired: false,
        check_access: false,
      },
    });
    setIsLeavingGroup(false);
    flashSuccess(LEFT_GROUP_SUCCESSFULLY, false)(dispatch);
    closeModal();
  };

  const updateBoard = async (values) => {
    values = sanitizeParams(values);
    setIsSubmitting(true);

    if (groupAttachmentId) {
      values.attachment_id = groupAttachmentId;
    }
    const response = await updateTeamSpaceMutation({
      variables: values,
    });
    const data = response.data;
    let space = data.updateBoard,
      errors = {};

    if (space.errors.length > 0) {
      space.errors.forEach((error) => (errors[error.field] = error.message));
      dispatch(openInfoModal('general', 'error_message', {}));
      return errors;
    } else {
      const flashMessage = isEditView()
        ? GROUP_UPDATED_SUCCESSFULLY
        : GROUP_ADDED_SUCCESSFULLY;
      closeModal();
      flashSuccess(flashMessage, false)(dispatch);
      !isEditView() &&
        EventTracker.trackForEntity('create_space_saved', {
          ...space,
          doctor_name: currentUser.graph.name,
        });

      navigate(`/${workspace.identifier}/team/group/${space.nice_id}`);

      // Push new Group data in store updater
      StoreUpdater.addDataInRecords(
        space,
        {
          page: 0,
          type: 'SubSpaces',
          perPage: GROUPS_BATCH_SIZE,
          text_query: '',
          id_query: workspace?.nice_id,
        },
        { query: GROUP_SIDEBAR_LIST, isReverse: true },
      );
    }
  };

  const followGroup = async (groupId) => {
    await followGroupMutation({
      variables: {
        followeeId: groupId,
      },
    });
    flashSuccess(GROUP_NOTIFICATIONS_UNMUTED_SUCCESSFULLY, false)(dispatch);
  };

  const unfollowGroup = async (groupId) => {
    await unfollowGroupMutation({
      variables: {
        followeeId: groupId,
      },
    });
    flashSuccess(GROUP_NOTIFICATIONS_MUTED_SUCCESSFULLY, false)(dispatch);
  };

  const showWarningIfRequired = (values) => {
    let privateObject = values.privateObjectAdded,
      privateContentWarningVisible =
        currentUser.graph.show_private_content_warning;
    values = omit(values, 'privateObjectAdded');
    if (isEditView()) {
      if (privateContentWarningVisible && privateObject) {
        props.openInfoModal('space', 'private_content_added', {
          contentInterpolations: {
            entity_type: privateObject.__typename.toLowerCase(),
          },
          onSuccess: updateBoard.bind(this, values),
        });
      } else {
        updateBoard(values);
      }
    } else {
      updateBoard(values);
    }
  };

  const onSubmit = (values) => {
    if (isSubmitPermitted()) {
      showWarningIfRequired(values);
    } else {
      props.openUpgradeModal();
    }
  };

  // Effects when teamSpaceModal is opened from different locations.
  useEffect(() => {
    if (teamSpaceModal.entity) {
      setInitialValues((prevState) => ({
        ...prevState,
        entity: teamSpaceModal.entity,
      }));
    }
    if (teamSpaceModal.userId) {
      setInitialValues((prevState) => ({
        ...prevState,
        assignee: teamSpaceModal.userId,
      }));
    }
  }, [teamSpaceModal]);

  return {
    followGroup,
    unfollowGroup,
    initialValues,
    selectedGroupImageUrl,
    onGroupBannerChange,
    teamSpaceModal,
    showDeleteModal,
    isSubmitDisabled,
    uploadPercentage,
    isSubmitting,
    showDeleteModalView,
    isEditView,
    closeModal,
    onSubmit,
    isArchiving,
    archiveSpace,
    leaveGroup,
    isLeavingGroup,
    groupFormData,
  };
};

export default useGroupModal;
