import { Component } from 'react';
import { loader as queryLoader } from 'graphql.macro';

import { getIconClass } from 'app/utils/entitiesHelper';
import { isWorkspaceInception } from 'app/utils/Workspace/generalHelper';

const SPACE_INVITATION_RESPONSE = queryLoader(
  'app/graphql/SpaceMemberRequestResponse.gql',
);

export default class SpaceRequests extends Component {
  state = {
    requestInProgress: false,
  };

  getNameForButton() {
    return (this.props.onlyIconRequired || this.props.icon) && !this.getText()
      ? 'BtnIcon'
      : this.props.btnType;
  }

  getIconClass() {
    return getIconClass(this.props.icon);
  }

  getText() {
    return !this.props.onlyIconRequired && this.props.text;
  }

  isSpaceOwner() {
    return this.props.currentUser.graph.id === this.props.space.user.id;
  }

  addDisabledClass() {
    return this.state.requestInProgress ? ' disabled-only ' : '';
  }

  isSourceSpaceDetailPage() {
    return !['card', 'notification'].includes(this.props.source);
  }

  performAction = async (action, event, options) => {
    let space = this.props.space,
      userId = this.props.requestingUser && +this.props.requestingUser.id,
      isCurrentUser = userId === +this.props.currentUser.graph.id;
    this.props.onAction && (await this.props.onAction(options));
    this.setState({ requestInProgress: true });
    event && event.preventDefault();
    event && event.stopPropagation();
    this.props.client
      .mutate({
        mutation: SPACE_INVITATION_RESPONSE,
        variables: {
          userId: userId,
          email: this.props.requestingEmail,
          spaceId: space.nice_id,
          accept_response: action,
          spaceCardSummaryRequired: this.props.source === 'notification',
          check_access: false,
        },
      })
      .then(({ data }) => {
        this.setState({ requestInProgress: false });
        this.closeModal && this.closeModal();
        let { success, entity, error } = data.spaceMemberRequestResponse;
        if (!success) {
          this.props.flashError(error, false);
        } else {
          isCurrentUser &&
            this.isSourceSpaceDetailPage() &&
            this.moveToSpaceIndex &&
            this.moveToSpaceIndex();
          this.props.onSuccess(entity.board);
          entity.user &&
            isCurrentUser &&
            this.props.updateCurrentUserGraph(entity.user);
          //ToDo cloudberry: revisit inception logic
          // if(action && isWorkspaceInception())
          //   window.location.pathname = '/explore';
        }
      });
  };
}
