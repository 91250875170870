import Authenticate from 'app/services/Authenticate.js';

import {
  ACTION_BY_GUEST_USER,
  UPDATE_GUEST_USER,
  UPDATE_GUEST_USER_DATA,
} from 'app/actions/types';

export default function guestUser(state = null, action, metaInfo = null) {
  // Rehydrate
  if (!state) {
    state = {};
    const token = Authenticate.getItem('guestToken');
    state = { token };
  }

  switch (action.type) {
    case UPDATE_GUEST_USER:
      return { ...state };
    case UPDATE_GUEST_USER_DATA:
      return { ...state, data: action.data };
    case ACTION_BY_GUEST_USER:
      return { ...state, action: action.action, metaInfo: action.metaInfo };
    default:
      return state;
  }
}
