import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import ConnectToSupport from 'app/components/ConnectToSupport';
import SocialStrip from 'app/components/Shared/SocialStrip';

class Footer extends Component {
  state = {
    openFaqModal: false,
  };
  el = document.createElement('div');

  componentDidMount() {
    let footerNode = document.getElementById('footer');
    this.setState({ footerNode }, () => {
      this.state.footerNode && this.state.footerNode.appendChild(this.el);
    });
  }

  componentWillUnmount() {
    this.state.footerNode && this.state.footerNode.removeChild(this.el);
  }

  renderContainer() {
    if (this.props.usePortal) {
      if (this.state.footerNode)
        return ReactDOM.createPortal(this.renderContent(), this.el);
    } else {
      return this.renderContent();
    }
  }

  getMobileConnectToSupportProps() {
    return {
      text: 'Contact Us',
      viewType: 'link',
    };
  }

  getNonMobileConnectToSupportProps() {
    return {
      text: 'Contact Us',
      viewType: 'link',
    };
  }

  renderContactSupport() {
    const { mobileDevice } = this.props.device,
      props = mobileDevice
        ? this.getMobileConnectToSupportProps()
        : this.getNonMobileConnectToSupportProps();
    return <ConnectToSupport openInNewTab={true} {...props} />;
  }

  renderContent() {
    return (
      <div className='container'>
        <div className='row'></div>
        <div className='row'>
          <div className='copyright'>
            © 2025&nbsp;Team Cloudberry Inc.&nbsp;
            {!this.props.device.mobileDevice && (
              <span className='ms-0'> All rights reserved.</span>
            )}
          </div>
          <div className='d-flex align-items-center'>
            <ul className='support'>
              <li>{this.renderContactSupport()}</li>
            </ul>
            <SocialStrip />
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div className='footer hidden-mobile mini os-footer empty-hide'>
          {this.renderContainer()}
        </div>
      </>
    );
  }
}
Footer = connect(({ device }) => ({ device }))(Footer);

Footer.defaultProps = {
  usePortal: false,
  linkRequired: false,
};
export default Footer;
