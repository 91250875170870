import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loader as queryLoader } from 'graphql.macro';
import { withRouter } from 'app/components/HOC/Router/withRouter';
import immutableUpdate from 'immutability-helper';

import AllCaughtUp from './AllCaughtUp';
import FeedView from 'app/components/FeedView';
import FeedGroupedDate from 'app/components/FeedsView/FeedGroupedDate';
import OrthoIcon from 'app/components/Shared/OrthoIcon';
import { translate } from 'app/actions/flashMessage';
import { isTimelineView } from 'app/utils/entitiesHelper';
import {
  convertToUnix,
  startOfDay,
  isCurrentDay,
  isYesterday,
} from 'app/utils/timeHelper';
import {
  compact,
  filter,
  keys,
  max,
  sortBy,
  groupBy,
  isEmpty,
  cloneDeep,
} from 'app/utils/osLodash';
import { COMMENTS_ACTIVITY_TYPE } from 'app/constants';
import StoreUpdater from 'app/services/StoreUpdater';
import { isTaskCommentActivity } from 'app/utils/taskHelper';

const RELATED_TASKS = queryLoader(
  'app/graphql/queries/Task/RelatedTaskWidget.gql',
);
const WORKSPACE_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/WorkspaceSubscription.gql',
);
const ENTITY_TYPE_SUBSCRIPTION = queryLoader(
  'app/graphql/subscriptions/EntityTypeSubscription.gql',
);

class FeedsView extends Component {
  constructor(props) {
    super(props);
    this.showAllCaughtUp = true;
    this.state = {
      isFeedUpToDate: true,
    };
  }

  componentDidUpdate() {
    if (
      this.state.isFeedUpToDate &&
      this.props.allSidebarCareNotifications.isNotificationAvailable
    ) {
      this.setState({ isFeedUpToDate: false });
    }
    this.props.updateCount(this.props.totalRecords);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  componentDidMount() {
    this.unsubscribe = () => {};
    if (this.props.listType === 'CareSpaceActivities') {
      this.careSpaceActivitiesSubscription();
    } else if (this.props.listType === 'CareSpaceFeed') {
      this.patientActivitySubscription();
    } else if (this.props.listType === 'PartnerSpaceActivities') {
      this.entityActivitySubscription();
    }
  }

  entityActivitySubscription = () => {
    let { recordId, recordType } = this.props;
    if (recordId && recordType) {
      this.unsubscribe = this.props.subscribeToMore({
        document: ENTITY_TYPE_SUBSCRIPTION,
        variables: { entityType: recordType, entityId: recordId },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity } = subscriptionData.data.entity_subscription;

          if (entity.__typename != 'Activity') return prev;

          let updatedData = cloneDeep(prev);

          let parentActivityId = entity.parent_activity_id || entity.id;
          const alreadyPresentActivity = updatedData.records_pager.results.find(
            (obj) => +obj.id === +parentActivityId,
          );
          if (alreadyPresentActivity) {
            updatedData.records_pager.results =
              updatedData.records_pager.results.filter(
                (obj) => +obj.id !== +parentActivityId,
              );
            if (entity.parent_activity_id) {
              alreadyPresentActivity.objects[0].additional_replies = [
                entity.objects[0],
              ];
            }
          } else {
            updatedData.records_pager.total += 1;
          }
          let payload = { records_pager: {} };
          payload.records_pager.results = {
            $unshift: alreadyPresentActivity
              ? [alreadyPresentActivity]
              : [entity],
          };
          updatedData = immutableUpdate(updatedData, payload);
          return Object.assign({}, prev, updatedData);
        },
      });
    }
  };

  patientActivitySubscription = () => {
    let workspace = this.props.workspace.data;
    if (workspace?.id) {
      this.unsubscribe = this.props.subscribeToMore({
        document: WORKSPACE_SUBSCRIPTION,
        variables: { workspaceId: workspace.id },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity } =
            subscriptionData.data.workspace_entity_update_subscription;

          if (entity.__typename != 'Feed') return prev;

          let updatedData = cloneDeep(prev);

          let feedId = entity.parent_activity_id || entity.id;
          const alreadyPresentFeed = updatedData.records.results.find(
            (obj) => +obj.id === +feedId,
          );
          if (alreadyPresentFeed) {
            updatedData.records.results = updatedData.records.results.filter(
              (obj) => +obj.id !== +feedId,
            );
            if (entity.parent_activity_id) {
              alreadyPresentFeed.objects[0].additional_entities = [
                entity.objects[0],
              ];
            }
          } else {
            updatedData.records.total += 1;
          }
          let payload = { records: {} };
          payload.records.results = {
            $unshift: alreadyPresentFeed ? [alreadyPresentFeed] : [entity],
          };
          updatedData = immutableUpdate(updatedData, payload);
          return Object.assign({}, prev, updatedData);
        },
      });
    }
  };

  careSpaceActivitiesSubscription = () => {
    let workspace = this.props.workspace.data;
    if (workspace?.id) {
      const activityType = this.props.idType,
        updateTaskRelatedWidget = this.updateTaskRelatedWidget,
        spaceId = this.props.idQuery;
      this.unsubscribe = this.props.subscribeToMore({
        document: WORKSPACE_SUBSCRIPTION,
        variables: { workspaceId: workspace.id },
        updateQuery(prev, { subscriptionData }) {
          if (isEmpty(subscriptionData.data)) return prev;
          const { entity } =
            subscriptionData.data.workspace_entity_update_subscription;
          let isPushRequired = !activityType;

          if (activityType === 'internal_activity') {
            isPushRequired = entity.care_space_internal_activity;
          } else if (activityType === 'patient_activity') {
            isPushRequired = entity.care_space_patient_activity;
          }

          if (!isPushRequired) return prev;
          if (isTaskCommentActivity(entity)) return prev;

          if (entity.__typename === 'Activity') {
            if (entity.source_type === 'TaskAddedActivity') {
              updateTaskRelatedWidget(entity.objects[0], entity.entities[0]);
            }
            if (entity.entities[0].nice_id != spaceId) return prev;

            let updatedData = cloneDeep(prev);

            let parentActivityId = entity.parent_activity_id || entity.id;
            const alreadyPresentActivity = updatedData.records.results.find(
              (obj) => +obj.id === +parentActivityId,
            );
            if (alreadyPresentActivity) {
              updatedData.records.results = updatedData.records.results.filter(
                (obj) => +obj.id !== +parentActivityId,
              );
              if (entity.parent_activity_id) {
                alreadyPresentActivity.objects[0].additional_replies = [
                  entity.objects[0],
                ];
              }
            } else {
              updatedData.records.total += 1;
            }
            let payload = { records: {} };
            payload.records.results = {
              $unshift: alreadyPresentActivity
                ? [alreadyPresentActivity]
                : [entity],
            };
            updatedData = immutableUpdate(updatedData, payload);
            return Object.assign({}, prev, updatedData);
          }
        },
      });
    }
  };

  updateTaskRelatedWidget = (task, space) => {
    if (space) {
      let entity = `Space::Base-${space.id}`;
      StoreUpdater.addRecord(
        task,
        {
          page: 0,
          perPage: 10,
          sortQuery: 'due_date_asc',
          type: 'EntityRelatedTasks',
          additional_filters: JSON.stringify({ entity }),
        },
        {
          query: RELATED_TASKS,
          isReverse: true,
        },
      );
    }
  };

  getActivityObject = (activity) => {
    if (!activity) {
      return null;
    }

    const object = activity.objects && activity.objects[0];
    const entity = activity.entities && activity.entities[0];

    if (object && object.__typename === 'Task') {
      return object;
    } else if (entity) {
      return entity;
    }

    return null;
  };

  reloadNewContent = () => {
    this.setState({
      isFeedUpToDate: true,
    });
    this.props.refetch();
  };

  displayAllCaughtUp(currentFeed, index) {
    let nextFeed = this.props.results[index + 1],
      userLastVisitedAt =
        this.props.currentUser.graph.explore_tab_previously_visited_at;
    if (
      nextFeed &&
      userLastVisitedAt &&
      nextFeed.visible_at < userLastVisitedAt &&
      currentFeed.visible_at > userLastVisitedAt
    ) {
      this.showAllCaughtUp = false;
      return <AllCaughtUp />;
    }
  }

  renderWidgets(afterFeedNumber) {
    return this.props.widgetAfterListItemNumber[afterFeedNumber];
  }

  getSuffixValue(date, index) {
    const suffixRequired =
      this.props.listType === 'CareSpaceFeed' &&
      index === 0 &&
      (isCurrentDay(convertToUnix(date)) || isYesterday(convertToUnix(date)));
    return suffixRequired ? 'in Patient Spaces' : '';
  }

  renderGroupedDate(data, index) {
    const suffix = this.getSuffixValue(data.date, index);
    return (
      <FeedGroupedDate
        key={data.date}
        index={index}
        date={data.date}
        suffix={suffix}
        infoIconText='CARE_SPACE_FEED_INFO_TEXT'
        isTimelineView={isTimelineView(this.props.listType)}
      />
    );
  }

  renderFeed(feed, index) {
    return (
      <FeedView
        feed={feed}
        key={feed.id}
        isInboxFeed={COMMENTS_ACTIVITY_TYPE.includes(this.props.listType)}
        listType={this.props.listType}
        entity={this.props.entity}
        feedType={this.props.feedType}>
        {this.showAllCaughtUp &&
          !this.props.allCaughtUpNotRequired &&
          this.displayAllCaughtUp(feed, index)}
      </FeedView>
    );
  }

  noActivityMessage() {
    switch (this.props.listType) {
      case 'MyActivities':
        return translate('NO_ACTIVITY_BY_ME');
      case 'NewUserActivities':
      case 'UserActivities':
        return translate('NO_ACTIVITY_BY_USER');
      default:
        return translate('NO_ACTIVITY_YET');
    }
  }

  renderNoFeedMessage() {
    return (
      <div className='no-cases-block'>
        <OrthoIcon
          name='empty-activity'
          dataHoverNotRequired={true}
          defaultClass='icon-no-content'
        />
        <div className='no-cases-message-block'>{this.noActivityMessage()}</div>
      </div>
    );
  }

  sortedFeeds() {
    let results = this.props.results;

    if (isTimelineView(this.props.listType)) {
      return results;
    } else {
      let pinnedFeeds = sortBy(
          filter(results, (c) => c.pinned),
          'pinned_at',
        ).reverse(),
        unpinnedFeeds = filter(results, (c) => !c.pinned);

      return pinnedFeeds.concat(unpinnedFeeds);
    }
  }

  groupedDateObject(date) {
    return {
      __typename: 'GroupedDate',
      date,
    };
  }

  renderFeeds() {
    const taskActivityCardId = new Set();
    if (!this.props.results.length) {
      return this.renderNoFeedMessage();
    } else if (false && COMMENTS_ACTIVITY_TYPE.includes(this.props.listType)) {
      const feeds = this.sortedFeeds();
      return feeds.map((feed, index) => {
        return this.renderFeed(feed, index);
      });
    } else {
      const feeds = this.sortedFeeds(),
        actionFeeds = feeds.map((feed) => {
          if (
            [
              'TaskAddedActivity',
              'TaskCompletedActivity',
              'LabelAddedActivity',
              'GenericActivity',
            ].includes(feed.source_type)
          ) {
            // extract the task id from the feed
            let taskId = null,
              taskIdAlreadyPresent = false;
            if (feed.source_type === 'GenericActivity') {
              switch (feed.action) {
                case 'task_label_removed':
                  taskId = feed.objects[0].entity.id;
                  break;
                case 'task_assignee_updated':
                case 'task_description_updated':
                case 'task_due_date_updated':
                  taskId = feed.objects[0].id;
                  break;
                default:
                  taskId = null;
              }
            } else if (feed.source_type === 'LabelAddedActivity') {
              taskId = feed.objects[0].entity?.id;
            } else {
              taskId = feed.objects[0].id;
            }
            // if the task id is already present in the set, then we return the feed
            if (taskActivityCardId.has(taskId)) {
              taskIdAlreadyPresent = true;
            } else {
              // Insert the task id in the set
              taskActivityCardId.add(taskId);
            }
            return { ...feed, showTaskActivityCard: !taskIdAlreadyPresent };
          } else if (
            ['Feed', 'PublicFeed', 'Activity'].includes(feed.__typename)
          ) {
            return feed;
          }
        }),
        groupedByFeeds = groupBy(compact(actionFeeds), (feed) =>
          feed.pinned ? 'Pinned' : startOfDay(feed.visible_at),
        );
      let allObjectsWithGroupedDate = [];
      keys(groupedByFeeds).forEach((key) => {
        key !== 'Pinned' &&
          allObjectsWithGroupedDate.push(this.groupedDateObject(key));
        allObjectsWithGroupedDate.push(...groupedByFeeds[key]);
      });

      return allObjectsWithGroupedDate.map((feed, index) => {
        return (
          <>
            {feed.__typename === 'GroupedDate'
              ? !this.props.isGroupedDateRequired
                ? null
                : this.renderGroupedDate(feed, index)
              : this.renderFeed(feed, index)}
            {this.renderWidgets(index + 1)}
          </>
        );
      });
    }
  }

  renderRestOfWidgetsIfNotEnoughFeedsArePresent() {
    const feedsCount = this.props.results.length,
      widgetPositions = keys(this.props.widgetAfterListItemNumber),
      maxWidgetNumber = max(widgetPositions);

    if (feedsCount < maxWidgetNumber) {
      return widgetPositions
        .filter((position) => position > feedsCount)
        .map((position) => {
          return this.renderWidgets(position);
        });
    }
  }

  render() {
    return (
      <>
        {/* {!this.state.isFeedUpToDate &&
          this.props.source !== 'space-detail-discussion-care-patientview' && (
            <PillButton reloadNewContent={this.reloadNewContent} />
          )} */}
        <ul
          className='activity-listing list-unstyled mb-0'
          style={{ position: 'relative' }}>
          {this.renderFeeds()}
          {this.renderRestOfWidgetsIfNotEnoughFeedsArePresent()}
        </ul>
      </>
    );
  }
}

FeedsView = withRouter(FeedsView);
FeedsView.defaultProps = {
  widgetAfterListItemNumber: {},
  updateCount: () => {},
};
FeedsView = connect(
  ({ currentUser, device, workspace, allSidebarCareNotifications }) => ({
    currentUser,
    device,
    workspace,
    allSidebarCareNotifications,
  }),
  {},
)(FeedsView);
export default FeedsView;
