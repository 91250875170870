import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { isCareSpaceView } from 'app/selectors/source';
import { Form, Field } from 'react-final-form';
import { loader as queryLoader } from 'graphql.macro';
import { useMutation } from '@apollo/client';

import OsBtn from 'app/components/OsBtn';
import OsField from 'app/components/OsField';
import RecordsCollectionView from 'app/components/RecordsCollectionView';
import { isEmpty } from 'app/utils/osLodash';
import IntercomTracker from 'app/services/Trackers/IntercomTracker';

const CHOOSE_PROFILE_MUTATION = queryLoader(
  'app/graphql/mutations/Care/ChooseProfile.gql',
);
const ASSOCIATE_PROFILE_MUTATION = queryLoader(
  'app/graphql/mutations/Care/AssociateNewProfile.gql',
);

// Journey
//// Intro -> Select Patient -> Confirm Patient
//// Intro -> Select RP -> No RPs Present, so Select Relation -> Confirm RP
//// Intro -> Select RP -> RPs Present, Pick RP -> Confirm RP
//// Intro -> Select RP -> RPs Present, New RP -> Select Relation -> Confirm RP
//// Screens: Intro, ProfileTypePicker, Select RP, Select Relation, Confirm Patient, Confirm RP

const ModalStateEnum = {
  INTRO: 'intro',
  PROFILETYPE: 'profile_type_picker',
  SELECTRP: 'select_rp',
  SELECTRELATION: 'select_relation',
  CONFIRMPATIENT: 'confirm_patient',
  CONFIRMRP: 'confirm_rp',
};

const MODAL_CONTENT = {
  [ModalStateEnum.INTRO]: {
    title: 'Getting Started - Please read carefully',
    subTitle: (
      <>
        On Cloudberry, we will create individual profiles for the Patient and
        Related Family members.
        <b>
          Please make sure your choices are based on who you are and NOT who you
          represent.
        </b>
        This will help us assign access permissions accordingly.
      </>
    ),
  },
  [ModalStateEnum.PROFILETYPE]: {
    title: 'Are you the Patient or Related to the Patient?',
    subTitle: (
      <>
        Please select the Patient only if you are the Patient. For everyone
        else, please select related to Patient.
      </>
    ),
  },
  [ModalStateEnum.SELECTRP]: {
    title: 'Select your Profile',
    subTitle: (
      <>
        Here is some information on our system about related family members.
        Please select one of the profiles below if that is you. If you do not
        see your name, please add your profile
      </>
    ),
  },
  [ModalStateEnum.SELECTRELATION]: {
    title: 'Add your Profile & Select Relationship',
    subTitle: (
      <>
        Please pick your relationship to the patient. If you are the patient
        then start over and select the patient profile.
      </>
    ),
  },
  [ModalStateEnum.CONFIRMPATIENT]: {
    title: 'Confirm your selection - You are the Patient',
    subTitle: (
      <>
        You selected that you are the patient. You will be able to update your
        information in the next step
      </>
    ),
  },
  [ModalStateEnum.CONFIRMRP]: {
    title: 'Confirm your selection',
    subTitle: (
      <>
        Please make sure you review this information and make any required
        changes.
      </>
    ),
  },
};

const ChooseProfileModal = ({ space }) => {
  //guard
  const isCareView = useSelector(isCareSpaceView);

  const [chooseProfileMutation, { loading: requestInProgress }] = useMutation(
    CHOOSE_PROFILE_MUTATION,
  );
  const [associateProfileMutation, { loading: requestInProgressValue }] =
    useMutation(ASSOCIATE_PROFILE_MUTATION);
  const currentUser = useSelector((state) => state.currentUser);
  const [modalState, setModalState] = useState(ModalStateEnum.INTRO);

  const [relation, setRelation] = useState({
    label: '',
    value: '',
  });

  const onClose = () => null;
  const onCardClick = () => null;

  const initialValues = {};
  const patient = space.owner.patient;
  const results = useMemo(() => {
    let results = [];
    if (!space.is_patient_in_workspace)
      results.push({ ...patient, person_connections: [] });
    results.push(
      ...patient.person_connections.filter((pc) => !pc.related_person.user_id),
    );
    return results;
  }, [space, patient]);

  useEffect(() => {
    if (space.user_request?.verification_required) {
      IntercomTracker.hideWidgetLauncher();
    }

    return () => {
      if (space.user_request?.verification_required)
        IntercomTracker.showWidgetLauncher();
    };
  }, [space]);

  let form;
  const onSubmit = (value) => {};

  const sendAssociateNewProfileMutation = (value) => {
    if (value?.relationship) {
      return associateProfileMutation({
        variables: {
          careSpaceId: space.nice_id,
          relationId: value.relationship,
        },
      }).then(({ data: { associateNewProfile } }) => {
        if (associateNewProfile.success) {
        } else {
        }
      });
    }
  };

  const sendChooseProfileMutation = (value) => {
    if (value) {
      return chooseProfileMutation({
        variables: {
          careSpaceId: space.nice_id,
          personId: value,
        },
      }).then(({ data: { chooseProfile } }) => {
        if (chooseProfile.success) {
        } else {
        }
      });
    }
  };

  const setRelationValue = (field, value, label) => {
    setRelation({
      label,
      value,
    });
    form.change(field, value);
  };

  const currentUserData = () => {
    const data = currentUser.graph;
    return [
      {
        __typename: 'Person',
        entity_label: null,
        person_connections: [],
        id: data.id,
        first_name: data.first_name,
        gender: data.gender,
        last_name: data.last_name,
        name: `${data.name} (You)`,
        email: data.email,
        phone_number: null,
        address: '',
        relation: relation.label,
        avatar: null,
        large_avatar: null,
        date_of_birth: data.date_of_birth,
        initials: data.initials,
        nice_id: data.nice_id,
        frontend_url: 'https://www.mycloudberry.com/people/P35711',
        user_id: null,
        user_onboarded: false,
        last_shared_on_date: 1687448087,
        last_request: null,
        tags: [],
      },
    ];
  };

  const profileRecords = useMemo(() => {
    let data = {
      patient: [],
      relatedParties: [],
      records: [],
    };
    data.records = results.map((result) => {
      if (
        result.__typename === 'Person' &&
        !result.name.includes('(Patient)')
      ) {
        result.name += ' (Patient)';
        result.last_name += ' (Patient)';
        data.patient.push(result);
      } else {
        data.relatedParties.push(result);
      }
      return result;
    });
    return data;
  }, [results]);

  const renderModalHeader = () => {
    return (
      <>
        <h4 className='modal-title'>{MODAL_CONTENT[modalState].title}</h4>
        <p>{MODAL_CONTENT[modalState].subTitle}</p>
      </>
    );
  };

  const renderModalBody = (values) => {
    switch (modalState) {
      case ModalStateEnum.INTRO:
        return <></>;
      case ModalStateEnum.PROFILETYPE:
        return <></>;
      case ModalStateEnum.SELECTRP:
        return (
          <RecordsCollectionView
            results={profileRecords.relatedParties}
            totalRecords={profileRecords.relatedParties.count}
            cardGridIdentifier={'RelatedCareSpacemodal:CardCols'}
            cardProps={{
              onCardClick,
              selectionMode: true,
              cardClickRequired: true,
              isSelect: true,
              hideExpandAction: true,
            }}
          />
        );
      case ModalStateEnum.SELECTRELATION:
        return (
          <div className='col-12 os-menu-drop only-option-text'>
            <Field
              osType='selectInput'
              component={OsField}
              label=''
              name='relationship'
              async={true}
              queryType='RelatedPersonTypes'
              placeholder='Select'
              onChange={setRelationValue}
            />
          </div>
        );
      case ModalStateEnum.CONFIRMPATIENT:
        return (
          <RecordsCollectionView
            results={profileRecords.patient}
            totalRecords={profileRecords.patient.count}
            cardGridIdentifier={'RelatedCareSpacemodal:CardCols'}
            cardProps={{
              onCardClick,
              selectionMode: true,
              cardClickRequired: true,
              hideSelectionAction: true,
              cardSize: 'extra-small',
              hideExpandAction: true,
            }}
          />
        );
      case ModalStateEnum.CONFIRMRP:
        //either show selected person or show the current user
        let selectedParty = profileRecords.relatedParties.filter(
          (related) =>
            related.related_person.id.toString() === values.toString(),
        );
        if (selectedParty.length === 0) {
          return (
            <RecordsCollectionView
              results={currentUserData()}
              totalRecords={currentUserData().count}
              cardGridIdentifier={'RelatedCareSpacemodal:CardCols'}
              cardProps={{
                onCardClick,
                selectionMode: true,
                cardClickRequired: true,
                hideSelectionAction: true,
                cardSize: 'extra-small',
                hideExpandAction: true,
              }}
            />
          );
        } else {
          return (
            <RecordsCollectionView
              results={selectedParty}
              totalRecords={selectedParty.length}
              cardGridIdentifier={'RelatedCareSpacemodal:CardCols'}
              cardProps={{
                onCardClick,
                selectionMode: true,
                cardClickRequired: true,
                hideSelectionAction: true,
                cardSize: 'extra-small',
                hideExpandAction: true,
              }}
            />
          );
        }

      default:
        return <></>;
    }
  };

  const renderModalFooter = (values) => {
    let buttonDisabled = requestInProgressValue || requestInProgress;

    switch (modalState) {
      case ModalStateEnum.INTRO:
        return (
          <OsBtn
            extraClass='with-border action-btn'
            disabled={buttonDisabled}
            loaderRequired={buttonDisabled}
            name='BtnPrimary'
            text={'Okay'}
            onClick={() => {
              if (profileRecords.patient.length > 0) {
                setModalState(ModalStateEnum.PROFILETYPE);
              } else if (profileRecords.relatedParties.length > 0) {
                setModalState(ModalStateEnum.SELECTRP);
              } else {
                setModalState(ModalStateEnum.SELECTRELATION);
              }
            }}
          />
        );
      case ModalStateEnum.PROFILETYPE:
        return (
          <>
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnSecondary'
              text={'Patient'}
              onClick={() => {
                setModalState(ModalStateEnum.CONFIRMPATIENT);
              }}
            />
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnSecondary'
              text={'Related to Patient'}
              onClick={() => {
                if (profileRecords.relatedParties.length > 0) {
                  setModalState(ModalStateEnum.SELECTRP);
                } else {
                  setModalState(ModalStateEnum.SELECTRELATION);
                }
              }}
            />
          </>
        );
      case ModalStateEnum.SELECTRP:
        return (
          <>
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnSecondary'
              text={'Not Listed? Add your Profile'}
              onClick={() => {
                setModalState(ModalStateEnum.SELECTRELATION);
              }}
            />
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled || isEmpty(values)}
              loaderRequired={buttonDisabled}
              name='BtnPrimary'
              text={'Next'}
              onClick={() => {
                setModalState(ModalStateEnum.CONFIRMRP);
              }}
            />
          </>
        );
      case ModalStateEnum.SELECTRELATION:
        return (
          <>
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnSecondary'
              text={"Start Over, I'm the Patient"}
              onClick={() => {
                setModalState(ModalStateEnum.INTRO);
              }}
            />
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled || isEmpty(values.relationship)}
              loaderRequired={buttonDisabled}
              name='BtnPrimary'
              text={'Next'}
              onClick={() => {
                setModalState(ModalStateEnum.CONFIRMRP);
              }}
            />
          </>
        );
      case ModalStateEnum.CONFIRMPATIENT:
        return (
          <>
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnSecondary'
              text={"Start Over, I'm not the patient"}
              onClick={() => {
                setModalState(ModalStateEnum.INTRO);
              }}
            />
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnPrimary'
              text={"Yes, I'm the Patient"}
              onClick={() => {
                sendChooseProfileMutation(patient?.id);
              }}
            />
          </>
        );
      case ModalStateEnum.CONFIRMRP:
        return (
          <>
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnSecondary'
              text={'Start Over'}
              onClick={() => {
                setModalState(ModalStateEnum.INTRO);
              }}
            />
            <OsBtn
              extraClass='with-border action-btn'
              disabled={buttonDisabled}
              loaderRequired={buttonDisabled}
              name='BtnPrimary'
              text={'Confirm - I am related to the Patient'}
              onClick={() => {
                let selectedParty = profileRecords.relatedParties.filter(
                  (related) =>
                    related.related_person.id.toString() === values.toString(),
                );

                if (selectedParty.length === 0) {
                  sendAssociateNewProfileMutation(values);
                } else {
                  sendChooseProfileMutation(values);
                }
              }}
            />
          </>
        );
      default:
        return <></>;
    }
  };

  if (!isCareView) return null;

  return (
    <Modal
      show={space.user_request?.verification_required}
      onHide={onClose}
      animation={false}
      dialogClassName='os-grid-modal modal-carespace-related'
      backdropClassName='modal-backdrop-custom modal-backdrop-general'>
      <Form
        onSubmit={onSubmit}
        initialValues={initialValues}
        keepDirtyOnReinitialize={false}
        render={(props) => {
          form = props.form;
          return (
            <form
              name='addNewPatient'
              className='add_new_patient_form'
              onSubmit={props.handleSubmit}>
              <Modal.Header className='border-0 p-0'>
                {renderModalHeader()}
              </Modal.Header>
              <Modal.Body>{renderModalBody(props.values)}</Modal.Body>
              <Modal.Footer className='choose-profile-wrap space-between'>
                {renderModalFooter(props.values)}
              </Modal.Footer>
            </form>
          );
        }}
      />
    </Modal>
  );
};

export default ChooseProfileModal;
