import { combineReducers } from 'redux';

import activeConversation from './activeConversation';
import activeGroup from './activeGroup';
import activeResource from './activeResource';
import allSidebarCareNotifications from './allSidebarCareNotifications';
import assign from './assign';
import attachmentPreview from './attachmentPreview';
import authentication from './authentication';
import commentShare from './commentShare';
import contactPartyModal from './contactPartyModal';
import currentUser from './currentUser';
import currentUserSessionValid from './currentUserSessionValid';
import device from './device';
import directWorkSpaceLogin from './directWorkSpaceLogin';
import disableCareSpaceModal from './disableCareSpaceModal';
import drafts from './drafts';
import editImage from './editImage';
import entityPreviewModal from './entityPreviewModal';
import flashMessage from './flashMessage';
import guestUser from './guestUser';
import globalSearchQuery from './globalSearchQuery';
import infoModal from './infoModal';
import lockNavbar from './lockNavbar';
import loginModal from './loginModal';
import navSearch from './navSearch';
import onboardingTips from './onboardingTips';
import patientModal from './patientModal';
import policiesModal from './policiesModal';
import postForm from './postForm';
import quickSwitcherModal from './QuickSwitcherModal';
import report from './report';
import shareModal from './shareModal';
import signup from './signup';
import source from './source';
import spaceLinks from './spaceLinks';
import systemConfig from './systemConfig';
import taskModal from './taskModal';
import taskPreviewModal from './taskPreviewModal';
import teamSpaceModal from './teamSpaceModal';
import universalBanner from './universalBanner';
import upgrade from './upgrade';
import playerModal from './playerModal';
import workspace from './workspace';
import folderModal from './folderModal';
import commandMenu from 'app/reducers/commandMenu';
import partnerSpaces from 'app/reducers/partnerSpaces';

export default function reducers(additionalReducers) {
  return combineReducers({
    allSidebarCareNotifications,
    activeConversation,
    activeGroup,
    activeResource,
    assign,
    attachmentPreview,
    authentication,
    commentShare,
    contactPartyModal,
    currentUser,
    currentUserSessionValid,
    device,
    disableCareSpaceModal,
    drafts,
    editImage,
    entityPreviewModal,
    flashMessage,
    folderModal,
    guestUser,
    globalSearchQuery,
    infoModal,
    directWorkSpaceLogin,
    partnerSpaces,
    loginModal,
    lockNavbar,
    navSearch,
    onboardingTips,
    patientModal,
    policiesModal,
    postForm,
    commandMenu,
    quickSwitcherModal,
    report,
    shareModal,
    signup,
    source,
    spaceLinks,
    systemConfig,
    taskModal,
    taskPreviewModal,
    teamSpaceModal,
    universalBanner,
    upgrade,
    playerModal,
    workspace,
    ...additionalReducers,
  });
}
